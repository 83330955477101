import React from "react";

// Component imports
import {LinearProgress} from "@material";

// Misc Imports
import {Theme} from "@app-types";
import {makeStyles, withStyles} from "@styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  margin: {
    height: 5,
    background: theme.palette.colors.background.progressBar,
  },
}));

const ColorLinearProgress = withStyles((theme) => ({
  barColorPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress);

export function ProgressBar() {
  const classes: any = useStyles({});

  return (
    <div className={classes.root}>
      <ColorLinearProgress className={classes.margin} />
    </div>
  );
}
