import { UserPoolClient } from "@apollo";
import { USER_POOL_GET_SIGN_IN_DATA, USER_POOL_GET_USER_DATA } from "@queries";

import { CognitoUser, CurrentUser, SignInData } from "@app-types";
import { User } from "@models";

export const fetchSignInData = async (
  cognitoUser: CognitoUser,
  currencyCode: string,
): Promise<User | null> => {
  if (!cognitoUser) return null;

  const userId = cognitoUser.attributes["custom:userid"];

  if (!userId) return null;

  try {
    const {
      data: { user },
    } = await UserPoolClient.query<CurrentUser>({
      query: USER_POOL_GET_USER_DATA,
      variables: { id: userId },
    });

    await UserPoolClient.query<SignInData>({
      query: USER_POOL_GET_SIGN_IN_DATA,
      variables: { id: userId, currency: currencyCode },
    });

    return user;
  } catch (err) {
    throw new Error(
      `[USER DATA FETCH SIGN IN ERROR]: Unable to fetch Mongo DB user data for user ID: ${userId}. Reason: ${err}`,
    );
  }
};
