import React from "react";

// Component imports
import { ProfilePhoto } from "@components";
import { BitcoinIcon, ExpandMoreIcon } from "@icons";
import { Grid, IconButton, Skeleton, Typography } from "@material";

import { NavbarMenuDropdown } from "./navbar-menu-dropdown";

// Misc Imports
import { AffiliateServiceArea, ExchangeRate, Theme } from "@app-types";
import { Amount, User, Wallet } from "@models";
import { makeStyles } from "@styles";

const useStyles = makeStyles((theme: Theme) => ({
  initialsBackground: {
    backgroundColor: theme.palette.colors.profile.background,
    width: 36,
    height: 36,
    borderRadius: "50%",
  },
  initialsText: {
    color: theme.palette.white,
    fontSize: 12,
    fontWeight: 600,
  },
  userIconContainer: {
    padding: 6,
    paddingRight: 14,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  walletDisplayContainer: {
    color: theme.palette.white,
    minWidth: 98,
  },
  fiatBalanceText: {
    fontSize: 15,
    fontWeight: 600,
    color: theme.palette.black,
  },
  btcBalanceText: {
    display: "flex",
    alignItems: "center",
    fontSize: 13,
    fontWeight: 400,
    marginTop: "-4px",
    color: theme.palette.colors.text.subheader,
  },
  bitcoinIcon: {
    marginRight: 4,
    fill: theme.palette.colors.bitcoin,
  },
  dropdownIcon: {
    textAlign: "center",
    verticalAlign: "middle",
    fill: theme.palette.black,
    marginRight: 8,
  },
}));

type Props = {
  user: User;
  wallet: Wallet;
  country: AffiliateServiceArea;
  loading?: boolean;
};

export function NavbarMenuLarge({ user, wallet, country, loading }: Props) {
  const classes: any = useStyles({});

  const getUserInitials = (user) =>
    user.firstName.charAt(0).toUpperCase() +
    user.lastName.charAt(0).toUpperCase();

  const MenuSkeleton = () => (
    <>
      <Grid container item lg justify="flex-end">
        <IconButton
          aria-label="account-of-current-user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          className={classes.userIconContainer}
        >
          <Grid container justify="center" alignItems="center">
            <Skeleton
              variant="circle"
              animation="wave"
              height={36}
              width={36}
            />
          </Grid>
        </IconButton>
      </Grid>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems={"center"}
        className={classes.walletDisplayContainer}
      >
        <Skeleton animation="wave" width={98} height={20} />
        <Skeleton animation="wave" width={98} height={20} />
      </Grid>
      <Grid container justify="flex-end">
        <ExpandMoreIcon
          width={10}
          fill="#000000"
          className={classes.dropdownIcon}
        />
      </Grid>
    </>
  );

  const MenuLoaded = ({ user, wallet }: Props) => {
    const balanceIncludingPendingInFiat = new Amount(
      wallet.balanceIncludingPendingInFiat,
    );

    return (
      <>
        <Grid container item lg justify="flex-end">
          <IconButton
            aria-label="account-of-current-user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            className={classes.userIconContainer}
          >
            {user.profilePhotoUrl ? (
              <ProfilePhoto profilePhotoUrl={user.profilePhotoUrl} size={36} />
            ) : (
              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.initialsBackground}
              >
                <Typography className={classes.initialsText}>
                  {getUserInitials(user)}
                </Typography>
              </Grid>
            )}
          </IconButton>
        </Grid>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems={wallet ? "flex-start" : "center"}
          className={classes.walletDisplayContainer}
        >
          <Typography className={classes.fiatBalanceText}>
            {` ${
              country?.currencyCode
            } $${balanceIncludingPendingInFiat.unscaledValue.toFixed(2)}`}
          </Typography>
          <Typography className={classes.btcBalanceText}>
            <BitcoinIcon width={12} className={classes.bitcoinIcon} />
            {(wallet.balanceNotIncludingFrozen.btc.value / 100000000).toFixed(
              8,
            )}
          </Typography>
        </Grid>
        <Grid container justify="flex-end">
          <ExpandMoreIcon
            width={10}
            fill="#000000"
            className={classes.dropdownIcon}
          />
        </Grid>
      </>
    );
  };

  return (
    <NavbarMenuDropdown>
      {wallet && !loading ? (
        <MenuLoaded user={user} wallet={wallet} />
      ) : (
        <MenuSkeleton />
      )}
    </NavbarMenuDropdown>
  );
}
