import React, { useEffect } from "react";

import {
  currentCountryVar,
  currentUserVar,
  useReactiveVar,
  UserPoolClient,
} from "@apollo";
import { USER_POOL_GET_WALLET_DATA } from "@queries";

// Misc Imports
import { AffiliateServiceArea } from "@app-types";
import { useUpdateUser } from "@hooks";
import { Wallet } from "@models";
import {
  getAffiliateServiceArea,
  isServerSideRendering,
  ModalHelper,
} from "@utils";

// Component imports
import { NavbarLocaleSelectionButton } from "./navbar-locale-selection-button";

export function NavbarLocaleSelectionContainer() {
  if (isServerSideRendering()) return null;

  const country = useReactiveVar(currentCountryVar);
  const user = useReactiveVar(currentUserVar);
  const { updateUser, updateUserLocaleIfMissing } = useUpdateUser({
    onCompleted: ModalHelper.close,
  });

  const localStorageLanguage = localStorage.getItem("i18nextLng");

  // Currently just used for backwards compatibility to update old user accounts with null locale fields.
  useEffect(() => {
    if (user) updateUserLocaleIfMissing(user);
  }, [user]);

  const handleModalSubmit = async (localeId: string): Promise<void> => {
    const countryCode = localeId.split("-")[0];
    const languageCode = localeId.split("-")[1];

    const previousCountry = localStorage.getItem("userCountry");
    const previousLanguage = localStorage.getItem("i18nextLng");

    const changeLanguage = !!(languageCode !== previousLanguage);
    const changeCountry = !!(countryCode !== previousCountry);

    // No change
    if (!changeLanguage && !changeCountry) {
      ModalHelper.close();
      return;

      // Change country but not language
    } else if (!changeLanguage && changeCountry) {
      const newCountry: AffiliateServiceArea = getAffiliateServiceArea(
        countryCode,
      );

      if (user) {
        updateUser({ country: countryCode });
        await UserPoolClient.query<{ wallet: Wallet }>({
          query: USER_POOL_GET_WALLET_DATA,
          variables: { id: user?.id, currency: newCountry?.currencyCode },
          fetchPolicy: "network-only",
        });
      }

      localStorage.setItem("userCountry", countryCode);
      currentCountryVar(newCountry);
      ModalHelper.close();

      // Change language but not country
    } else if (changeLanguage && !changeCountry) {
      if (user) updateUser({ language: languageCode.toLowerCase() });

      localStorage.setItem("i18nextLng", languageCode);
      ModalHelper.close();
      window.location.reload();

      //Change both language and country
    } else if (changeLanguage && changeCountry) {
      if (user)
        updateUser({
          country: countryCode,
          language: languageCode.toLowerCase(),
        });

      // Refetch of Wallet handled by initializeAppStartup function after app refresh
      localStorage.setItem("userCountrySavedBeforeRefresh", countryCode);
      localStorage.setItem("i18nextLng", languageCode);

      ModalHelper.close();
      window.location.reload();
    }
  };

  const localeSelectionModalOpen = (): void => {
    const currentLocaleId = `${
      country.countryCode
    }-${localStorageLanguage.toLowerCase()}`;

    const localeSelectionModalProps: any = {
      currentLocaleId,
      handleModalSubmit,
    };

    ModalHelper.open({
      modalType: "localeSelection",
      modalProps: localeSelectionModalProps,
    });
  };

  return (
    <NavbarLocaleSelectionButton
      currentCountry={country}
      onClick={localeSelectionModalOpen}
    />
  );
}
