import { modalStateVar } from "@apollo";
import { ModalState } from "@app-types";

export class ModalHelper {
  static open = ({ modalType, modalProps, modalOptions }: ModalState): void => {
    modalStateVar({
      modalType,
      modalProps,
      modalOptions,
    });
  };

  static close = (): void => {
    modalStateVar({
      modalType: null,
      modalProps: null,
      modalOptions: null,
    });
  };
}
