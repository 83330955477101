import React, { useEffect, useState } from "react";

// Component imports
import {
  CoinmilesLogo,
  EmailSignupField,
  ExternalLink,
  Link,
} from "@components";
import { FacebookPlainIcon, InstagramIcon, TwitterIcon } from "@icons";
import { Grid, Typography } from "@material";

// Misc Imports
import { Theme } from "@app-types";
import { useTranslation } from "@i18n";
import { makeStyles } from "@styles";

const useStyles = makeStyles((theme: Theme) => ({
  footerRoot: {
    height: "auto",
    width: "100%",
    margin: 0,
    backgroundColor: theme.palette.black,
  },
  container: {
    maxWidth: 1400,
    margin: "0px auto 0px auto",
    paddingTop: 30,
    paddingBottom: 30,
    marginBottom: 0,
    width: "80%",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    width: 130,
    marginBottom: 16,
    filter:
      "grayscale(100%) brightness(0%) invert(99%) sepia(4%) saturate(24%) hue-rotate(151deg) brightness(106%) contrast(100%);",
  },
  signupContainer: {
    marginBottom: 16,
  },
  linksColumnsContainer: {
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-evenly",
    },
  },
  newsletterText: {
    marginBottom: 8,
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.white,
    opacity: 0.7,
  },
  footerText: {
    height: 21,
    display: "flex",
    alignItems: "flex-start",
    marginBottom: 8,
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.white,
  },
  linkContainer: {
    opacity: 0.7,
    "&:hover": {
      opacity: 1,
    },
  },
  textContainer: {
    marginBottom: 30,
  },
  icon: {
    width: 15,
    marginRight: 10,
    marginBottom: 10,
    fill: theme.palette.white,
    opacity: 0.7,
  },
}));

export function Footer() {
  const classes: any = useStyles({});
  const { t } = useTranslation(["footer", "common"]);

  return (
    <footer className={classes.footerRoot}>
      <div className={classes.container}>
        <Grid container justify="flex-start" className={classes.title}>
          <Link to="/">
            <CoinmilesLogo className={classes.logo} />
          </Link>
        </Grid>
        <Grid container direction="row" justify="space-between">
          <Grid
            container
            item
            justify="flex-start"
            alignItems="flex-start"
            xs={12}
            sm={6}
            md={6}
            lg={6}
          >
            <Grid item xs={12} sm={10} md={8} lg={8}>
              <Typography className={classes.newsletterText}>
                {t("joinNewsletter")}
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={8}
              lg={8}
              className={classes.signupContainer}
            >
              <EmailSignupField />
            </Grid>
          </Grid>
          <Grid
            container
            item
            md={6}
            lg={6}
            className={classes.linksColumnsContainer}
          >
            <Grid
              container
              item
              direction="column"
              alignItems="flex-start"
              xs={12}
              sm={4}
              md={4}
            >
              <ExternalLink
                to="https://www.coinmiles.io/ambassador"
                className={classes.linkContainer}
              >
                <Typography className={classes.footerText}>
                  {t("common:ambassador")}
                </Typography>
              </ExternalLink>
              <ExternalLink
                to="https://www.coinmiles.io/partners"
                className={classes.linkContainer}
              >
                <Typography className={classes.footerText}>
                  {t("common:joinPartner")}
                </Typography>
              </ExternalLink>
              <ExternalLink
                to="http://www.coinmiles.io/blog"
                className={classes.linkContainer}
              >
                <Typography className={classes.footerText}>
                  {t("common:blog")}
                </Typography>
              </ExternalLink>
              <ExternalLink
                to="mailto:info@coinmiles.io"
                className={classes.linkContainer}
              >
                <Typography className={classes.footerText}>
                  {t("common:contact")}
                </Typography>
              </ExternalLink>
            </Grid>
            <Grid
              container
              item
              direction="column"
              alignItems="flex-start"
              xs={12}
              sm={4}
              md={4}
              className={classes.textContainer}
            >
              <ExternalLink
                to="https://www.coinmiles.io/privacy"
                className={classes.linkContainer}
              >
                <Typography className={classes.footerText}>
                  {t("common:privacyPolicy")}
                </Typography>
              </ExternalLink>
              <ExternalLink
                to="https://www.coinmiles.io/usersterms"
                className={classes.linkContainer}
              >
                <Typography className={classes.footerText}>
                  {t("common:termsOfService")}
                </Typography>
              </ExternalLink>
            </Grid>
            <Grid
              container
              item
              direction="column"
              alignItems="flex-start"
              xs={12}
              sm={4}
              md={4}
            >
              <ExternalLink
                newTab
                to="https://www.instagram.com/getcoinmiles/"
                className={classes.linkContainer}
              >
                <Typography className={classes.footerText}>
                  <InstagramIcon className={classes.icon} />
                  Instagram
                </Typography>
              </ExternalLink>
              <ExternalLink
                newTab
                to="https://www.facebook.com/getcoinmiles/"
                className={classes.linkContainer}
              >
                <Typography className={classes.footerText}>
                  <FacebookPlainIcon className={classes.icon} />
                  Facebook
                </Typography>
              </ExternalLink>
              <ExternalLink
                newTab
                to="https://twitter.com/getcoinmiles"
                className={classes.linkContainer}
              >
                <Typography className={classes.footerText}>
                  <TwitterIcon className={classes.icon} />
                  Twitter
                </Typography>
              </ExternalLink>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </footer>
  );
}
