import React, { useEffect } from "react";

// Component imports
import { Grid, Paper, Skeleton, Typography } from "@material";
import { DialogButton } from "@components";

// Misc Imports
import { useTranslation } from "@i18n";
import { makeStyles } from "@styles";
import { Theme } from "@app-types";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 1400,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 4,
    width: "80%",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  header: {
    fontSize: 42,
    fontWeight: 800,
    color: "black",
    marginBottom: 16,
  },
  displaysContainer: {
    marginTop: 21,
    marginBottom: 20,
    marginLeft: "auto",
    marginRight: "auto",
    minHeight: 630,
    [theme.breakpoints.up("sm")]: {
      flexWrap: "nowrap",
    },
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  displayContainer: {
    [theme.breakpoints.up("sm")]: {
      top: 88,
      marginRight: 16,
    },
    [theme.breakpoints.down("sm")]: {
      top: 56,
      marginBottom: 16,
      marginRight: 0,
    },
  },
  walletContainer: {
    backgroundColor: "white",
    border: `1px solid #EBEBEB`,
    width: "100%",
    height: 419,
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      padding: 15,
    },
  },
  balanceHeader: {
    fontSize: 24,
    fontWeight: 500,
  },
  marketPriceContainer: {
    width: "100%",
    height: "auto",
    padding: 20,
    paddingLeft: 24,
    border: "1px solid #9e9e9e",
    backgroundColor: "#FAFAFA",
    borderRadius: 4,
    marginTop: 30,
    marginBottom: 30,
    paddingTop: 27,
    paddingBottom: 27,
  },
  sendButton: {
    backgroundColor: "black",
    width: "100%",
    textTransform: "none",
    borderRadius: 0,
    color: "white",
    fontSize: 16,
    fontWeight: 400,
    transitionProperty: "background-color, color",
    transitionDuration: "0.4s",
    transitionTimingFunction: "ease-out",
    "&:hover": {
      backgroundColor: "#303030",
      color: "#F4F4F4",
      transitionProperty: "color, background-color",
      transitionDuration: "0.4s",
    },
  },
  walletActivity: {
    backgroundColor: "white",
    border: `1px solid #EBEBEB`,
    width: "100%",
    padding: 24,
    height: 419,
  },
  transactionHeader: {
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-end",
    },
  },
  transactionBalanceHeader: {
    fontSize: 24,
    fontWeight: 500,
  },
}));

export function WalletSkeleton() {
  const classes: any = useStyles({});
  const { t } = useTranslation(["wallet", "common"]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid
      container
      item
      direction="row"
      xs={12}
      sm={8}
      md={12}
      className={classes.marketPriceContainer}
      style={{
        padding: 8,
      }}
    >
      <Skeleton
        variant="rect"
        animation="wave"
        width="100%"
        height={84}
        style={{ borderRadius: 4 }}
      />
    </Grid>
  );
}
