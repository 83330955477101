import { gql } from "@apollo/client";

export const MUTATION_UPDATE_USER = gql`
  mutation UpdateUser(
    $id: ID!
    $emailAddress: String
    $firstName: String
    $lastName: String
    $country: String
    $language: String
    $profilePhotoUrl: String
    $didLogin: Boolean
  ) {
    user: updateUser(
      id: $id
      emailAddress: $emailAddress
      firstName: $firstName
      lastName: $lastName
      country: $country
      language: $language
      profilePhotoUrl: $profilePhotoUrl
      didLogin: $didLogin
    ) {
      id
      emailAddress
      firstName
      lastName
      country
      language
      profilePhotoUrl
      didLogin
    }
  }
`;
