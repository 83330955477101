export const en = {
  signUp: "Sign up to continue",
  firstName: "First name",
  lastName: "Last name",
  emailAddress: "Email address",
  password: "Password",
  passwordConfirmation: "Password Confirmation",
  signUpWithEmail: "Continue with email",
  signUpDisclaimer: {
    one: "By signing up, I agree to Coinmiles'",
    two: " Terms of Service",
    three: " and",
    four: " Privacy Policy",
    five: ". Coinmiles is only available in Canada at this time.",
  },
  alreadyMember: "Already a member?",
  modal: {
    earnAt: "Shop at",
    weNeedToKnow: {
      one: "We need to know who should get rewarded!",
      two: "Good news: when you create an account, ",
      three: "we'll automatically put 0.00005 Bitcoin ",
      four: "in your wallet.",
    },
    earnFreeBitcoin: "Get free Bitcoin by joining Coinmiles",
    signUpInOneClick: "Sign-up in one click ",
    receive: " and automatically receive 0.000005 Bitcoin in your account.",
  },
  drawer: {
    letsCheckOut: "Let's check out the deals",
    aCoolGift: "A cool gift for you",
    exploreDeals: {
      one: "Explore the deals for free ",
      two: "and automatically receive 0.00005 Bitcoin.",
    },
    loginToViewDeals: {
      one: "Log in to view the offers. It's ",
      two: "fast, free ",
      three: "and we give you 0.00005 BTC as a welcome bonus.",
      four: "Sign up and start using Coinmiles right away. It's ",
    },
  },
  join: {
    joinMessage: {
      one: "Join ",
      two: "Coinmiles ",
      three:
        "to starting earning Bitcoin cashback when you shop online. Enjoy more than 200+ brands!",
    },
  },
  success: {
    thanks: "Thanks for joining Coinmiles!",
    confirmation: {
      one: "We have sent a confirmation email to",
      two: "We have resent the confirmation email to",
      three: "Please confirm your account to begin using Coinmiles.",
      four: "The email may take up to a minute to appear.",
      five: "Please confirm your account to begin using Coinmiles.",
      six: "Check your spam or junk folder if you still do not see it.",
    },
    didntReceive: "Didn't receive an email?",
    sendAgain: " Send again",
  },
};
