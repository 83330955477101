import { RedirectData, StaticOffer } from "@app-types";

export function createRedirectData(
  offer: StaticOffer,
  userId: string,
): RedirectData {
  const { advertiserUrl, affiliateNetwork, trackingUrl } = offer;

  const redirectUrls: { [key: string]: string } = {
    RAKUTEN: `${trackingUrl}&u1=${userId}`,
    FLEX: `${trackingUrl}&fobs=${userId}&url=${advertiserUrl}`,
    EBAY: `${trackingUrl}&customid=${userId}`,
  };

  const redirectUrl: string = redirectUrls[affiliateNetwork];

  return {
    redirectUrl,
    advertiserLogoImage: offer.advertiserLogoImage,
    payoutText: offer.payoutText,
    referrer: "offer-redirect",
  };
}
