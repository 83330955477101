import { gql } from "@apollo/client";

export const SCREEN_GET_PROFILE_DATA = gql`
  query ScreenGetProfileData($id: ID!, $currency: String) @client {
    wallet: getWallet(id: $id, currency: $currency) {
      userId
      balanceIncludingPendingInFiat {
        currency
        value
      }
      balanceNotIncludingFrozen {
        btc {
          value
        }
      }
      transactions {
        id
      }
    }
    linkedCards: getLinkedCards(id: $id) {
      id
      brand
      expMonth
      expYear
      externalId
      last4
    }
  }
`;
