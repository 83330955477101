import React from "react";

// Component imports
import {Link} from "@components";
import {Grid} from "@material";

// Misc Imports
import {Theme} from "@app-types";
import {useTranslation} from "@i18n";
import {makeStyles} from "@styles";

const useStyles = makeStyles((theme: Theme) => ({
  headerButtonContainer: {
    marginTop: 7,
    marginBottom: 7,
  },
  menuButton: {
    display: "flex",
    alignItems: "center",
    width: "auto",
    margin: "16px 31px 18px 31px",
    color: "#1F1F30",
    height: 24,
  },
  menuButtonText: {
    fontSize: 16,
    fontWeight: 500,
    height: 24,
    transitionProperty: "color",
    transitionDuration: "0.4s",
    transitionTimingFunction: "ease-out",
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.main,
      transitionProperty: "color",
      transitionDuration: "0.3s",
    },
  },
  localeCountainer: {
    display: "flex",
    alignItems: "center",
    width: "auto",
  },
  countryText: {
    paddingTop: 2,
    fontWeight: 600,
    marginLeft: 8,
    marginRight: 24,
  },
}));

type Props = {
  userId: string;
};

export function NavbarButtons({userId}: Props) {
  const classes: any = useStyles({});
  const {t} = useTranslation("navbar");

  const walletRedirectLink: string = userId ? `/wallet` : "/sign-in";

  const setActiveStyle = ({location}) =>
    location &&
    location.state &&
    location.state.referrer === "wallet-sign-in" && {
      style: {
        color: "#FF3451",
        transition: "color 0.5s",
        textDecoration: "none",
      },
    };

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classes.headerButtonContainer}
      md
      lg
    >
      <Link
        to="/"
        className={classes.menuButton}
        activeStyle={{color: "#FF3451", transition: "color 0.5s"}}
      >
        <span className={classes.menuButtonText}>{t("shopOnline")}</span>
      </Link>
      <Link
        to={walletRedirectLink}
        state={{referrer: "wallet-sign-in"}}
        className={classes.menuButton}
        getProps={setActiveStyle}
      >
        <span className={classes.menuButtonText}>{t("myWallet")}</span>
      </Link>
      {userId && (
        <Link
          to="/what-i-like"
          className={classes.menuButton}
          activeStyle={{color: "#FF3451", transition: "color 0.5s"}}
        >
          <span className={classes.menuButtonText}>{t("whatILike")}</span>
        </Link>
      )}
    </Grid>
  );
}
