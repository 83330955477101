import {Assets} from "../images";

interface CouponCategory {
  image: string;
  name: string;
  icon: string;
  circularIcon: string;
}

const couponCategories: CouponCategory[] = [
  {
    image: Assets.CATEGORY_IMAGE_FOOD_AND_DRINKS,
    name: "foodAndDrinks",
    icon: Assets.CATEGORY_ICON_FOOD_AND_DRINKS,
    circularIcon: Assets.CATEGORY_CIRCULAR_ICON_FOOD_AND_DRINKS,
  },
  {
    image: Assets.CATEGORY_IMAGE_THINGS_TO_DO,
    name: "thingsToDo",
    icon: Assets.CATEGORY_ICON_THINGS_TO_DO,
    circularIcon: Assets.CATEGORY_CIRCULAR_ICON_THINGS_TO_DO,
  },
  {
    image: Assets.CATEGORY_IMAGE_BEAUTY,
    name: "beauty",
    icon: Assets.CATEGORY_ICON_BEAUTY,
    circularIcon: Assets.CATEGORY_CIRCULAR_ICON_BEAUTY,
  },
  {
    image: Assets.CATEGORY_IMAGE_SERVICES,
    name: "services",
    icon: Assets.CATEGORY_ICON_SERVICES,
    circularIcon: Assets.CATEGORY_CIRCULAR_ICON_SERVICES,
  },
];

export {couponCategories};
