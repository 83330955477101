import { currentCountryVar } from "@apollo";

import { AffiliateServiceArea } from "@app-types";
import {
  getAffiliateServiceArea,
  getUserCountry,
  isServerSideRendering,
} from "@utils";

export const LocaleUtils = {
  isCountrySupported: function (countryCode: string): boolean {
    return this.supportedCountries.includes(countryCode);
  },

  isLanguageSupported: function (languageCode: string): boolean {
    return this.supportedLanguages.includes(languageCode);
  },

  setStartupCountry: async function (): Promise<string> {
    const localStorageCountry = localStorage.getItem("userCountry");

    let assignedCountry: string;

    if (localStorageCountry) {
      assignedCountry = localStorageCountry;
      currentCountryVar(getAffiliateServiceArea(assignedCountry));
    } else {
      // Otherwise detects country using IPstack API and returns its code.
      const countryCode = await getUserCountry();
      const isSupported = this.isCountrySupported(countryCode);

      if (isSupported) {
        assignedCountry = countryCode;
        localStorage.setItem("userCountry", assignedCountry);
        currentCountryVar(getAffiliateServiceArea(assignedCountry));
      } else {
        // Defaults to Canada if user returns neither US or CA country codes.
        assignedCountry = "CA";
        localStorage.setItem("userCountry", assignedCountry);
        currentCountryVar(getAffiliateServiceArea(assignedCountry));
      }
    }

    return assignedCountry;
  },

  setStartupLanguage: function (): string {
    const localStorageLanguage = localStorage.getItem("i18nextLng");

    let assignedLanguage: string = localStorageLanguage;
    // If localStorage language is not supported, defaults to English
    if (
      localStorageLanguage === null ||
      !this.isLanguageSupported(localStorageLanguage)
    ) {
      assignedLanguage = "en";
      localStorage.setItem("i18nextLng", assignedLanguage);
    }

    return assignedLanguage;
  },

  setSignInLocale: async function (): Promise<{
    userCountry: AffiliateServiceArea;
    userLanguageCode: string;
  }> {
    let userCountryCode: string;
    const savedCountry: string = localStorage.getItem("userCountry");

    if (!!savedCountry) {
      userCountryCode = savedCountry;
    } else {
      const countryCode = await getUserCountry();
      const isSupported = LocaleUtils.isCountrySupported(countryCode);

      isSupported ? (userCountryCode = countryCode) : (userCountryCode = "CA");
    }

    let userLanguageCode: string;
    const savedLanguage: string = localStorage.getItem("i18nextLng");

    if (LocaleUtils.isLanguageSupported(savedLanguage)) {
      userLanguageCode = savedLanguage;
    } else {
      userLanguageCode = "en";
    }

    const userCountry = getAffiliateServiceArea(userCountryCode);

    return { userCountry, userLanguageCode };
  },

  assignLocaleIfNull: async function () {
    if (isServerSideRendering()) return;
    if (
      localStorage.getItem("userCountry") !== null ||
      localStorage.getItem("i18nextLng") !== null
    )
      return;

    await this.setStartupCountry();
    this.setStartupLanguage();
  },

  supportedCountries: ["CA", "US", "FR"],

  supportedLanguages: ["en", "fr"],
};
