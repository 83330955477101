import React from "react";

import {Theme} from "@app-types";
import {makeStyles} from "@styles";

const useStyles = (props) =>
  makeStyles((theme: Theme) => ({
    dividerLineContainer: {width: props.width || "100%"},
    divider: {
      position: "relative",
      top: 2,
      background: theme.palette.white,
      padding: "0 8px",
    },
    dividerText: {
      color: theme.palette.black,
      width: "100%",
      textAlign: "center",
      fontSize: 16,
      fontFamily: "Roboto, sans-serif",
      borderBottom: `1px solid ${theme.palette.colors.border.divider}`,
      lineHeight: "0.1em",
      margin: props.verticalSpacing
        ? `${props.verticalSpacing}px 0 ${props.verticalSpacing - 2}px`
        : "26px 0 24px",
    },
  }));

type Props = {
  text: string;
  width?: string | number;
  verticalSpacing?: number;
};

export function DividerLineWithText(props: Props) {
  const classes: any = useStyles(props)({});

  return (
    <div className={classes.dividerLineContainer}>
      <h2 className={classes.dividerText}>
        <span className={classes.divider}>{props.text}</span>
      </h2>
    </div>
  );
}
