import React from "react";
import Img from "gatsby-image";

type Props = {
  image: any;
  className?: string;
  style?: any;
  alt?: string;
};

export const Image = ({image, className, style, alt}: Props) => (
  <Img
    className={className}
    style={style}
    alt={alt}
    fluid={image}
    backgroundColor={"#D3D3D3"}
    loading="lazy"
    fadeIn={true}
  />
);
