import React from "react";
import {Auth} from "aws-amplify";
import {CognitoHostedUIIdentityProvider} from "@aws-amplify/auth/lib/types";

// Component imports
import {FacebookIcon} from "@icons";

// Misc Imports
import {Theme} from "@app-types";
import {useTranslation} from "@i18n";
import {makeStyles} from "@styles";

const useStyles = (props) =>
  makeStyles((theme: Theme) => ({
    socialLoginButton: {
      cursor: "pointer",
      outline: "none",
      fontFamily: "'Roboto', sans-serif",
      padding: 0,
      width: "100%",
      height: props.height || 56,
      backgroundColor: theme.palette.colors.facebook,
      color: theme.palette.white,
      fontWeight: 500,
      fontSize: 16,
      textTransform: "none",
      textAlign: "center",
      borderRadius: 10,
      minWidth: 250,
      marginBottom: 8,
      border: "1px solid transparent",
      "&:hover": {
        backgroundColor: theme.palette.colors.facebook,
      },
    },
    facebookIcon: {
      width: 24,
      marginRight: 9,
      [theme.breakpoints.down("xs")]: {
        marginRight: 12,
      },
    },
    innerLabel: {
      display: "flex",
      alignItems: "center",
      marginLeft: "auto",
      marginRight: "auto",
      justifyContent: "flex-start",
      width: 220,
    },
  }));

type Props = {
  handleClose?: any;
  height?: number | string;
  style?: any;
};

export function FacebookLoginButton(props: Props) {
  const classes: any = useStyles(props)({});
  const {t} = useTranslation("signIn");

  const {handleClose, style} = props;

  const loginWithFacebook = async (): Promise<void> => {
    try {
      Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Facebook,
        customState: "facebook",
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <button
      className={classes.socialLoginButton}
      onClick={() => {
        handleClose && handleClose();
        loginWithFacebook();
      }}
      style={style}
    >
      <div className={classes.innerLabel}>
        <FacebookIcon className={classes.facebookIcon} />
        {t("social.facebook")}
      </div>
    </button>
  );
}
