import React from "react";

// Component imports
import { ExternalLink } from "@components";
import { Grid, Paper, Typography } from "@material";
import { DownloadAppStoreIcon, DownloadGooglePlayIcon } from "@icons";

// Misc Imports
import { Theme } from "@app-types";
import { Assets } from "@assets";
import { useTranslation } from "@i18n";
import { makeStyles } from "@styles";

const useStyles = ({ width, fontSize }) =>
  makeStyles((theme: Theme) => ({
    paper: {
      borderRadius: 12,
      maxWidth: 800,
      width: width || 650,
      padding: 32,
      [theme.breakpoints.down("xs")]: {
        padding: 16,
        margin: "auto 16px auto 16px",
      },
    },
    dialog: {
      height: "auto",
      [theme.breakpoints.down("xs")]: {
        width: "90%",
        height: "auto",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    downloadText: {
      fontSize: 30,
      fontWeight: 600,
      textAlign: "center",
      marginTop: 22,
      marginBottom: 22,
      [theme.breakpoints.down("xs")]: {
        fontSize: 20,
        marginTop: 8,
        marginBottom: 8,
      },
    },
    dialogText: {
      fontSize: fontSize?.big || 20,
      fontWeight: 400,
      textAlign: "center",
      marginLeft: 40,
      marginRight: 40,
      marginBottom: 16,
      [theme.breakpoints.down("xs")]: {
        fontSize: fontSize?.small || 16,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    menuButtonMobile: { marginBottom: 8, width: "100%" },
    menuButton: { marginBottom: 8, width: 284 },
    backdrop: {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
    },
    linksContainer: {
      width: "auto",
      height: "auto",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: 8,
      [theme.breakpoints.down("xs")]: {
        marginLeft: "auto",
      },
    },
    logoContainer: {
      marginRight: 16,
      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
        marginBottom: 8,
      },
    },
    appStoreDownloadIcon: {
      marginRight: 16,
      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
        marginBottom: 8,
      },
    },
  }));

type Props = {
  messageStrings: string[];
  width?: number;
  logo?: any;
  fontSize?: { big?: number; small?: number };
};

export function AppDownloadModal({
  messageStrings,
  logo,
  width,
  fontSize,
}: Props) {
  const classes: any = useStyles({ width, fontSize })({});
  const { t } = useTranslation("common");

  const localStorageLanguage: string = localStorage.getItem("i18nextLng");

  const appDownloadLinks = {
    appStore: {
      en: "https://apps.apple.com/ca/app/coinmiles/id1479081866",
      fr: "https://apps.apple.com/ca/app/coinmiles/id1479081866",
    },
    googlePlay: {
      en:
        "https://play.google.com/store/apps/details?id=com.coinmiles.app&hl=en_CA",
      fr:
        "https://play.google.com/store/apps/details?id=com.coinmiles.app&hl=fr_CA",
    },
  };

  const messageParagraphs: JSX.Element[] = messageStrings.map(
    (string: string, index: number) => {
      return (
        <Typography key={index + 1} className={classes.dialogText}>
          {string}
        </Typography>
      );
    },
  );

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="column"
        className={classes.dialog}
      >
        <Grid item>
          <img
            style={{ width: 120 }}
            src={Assets.COINMILES_SQUARE_LOGO}
            alt="coinmiles-logo"
          />
        </Grid>
        <Grid container item justify="center">
          <Typography className={classes.downloadText}>
            {t("download")}
          </Typography>
        </Grid>
        <Grid container item justify="center">
          {messageParagraphs}
        </Grid>
        <Grid
          container
          item
          direction="row"
          justify="center"
          alignItems="flex-end"
          className={classes.linksContainer}
        >
          {logo && (
            <img
              className={classes.logoContainer}
              style={{ width: 54 }}
              src={logo}
              alt="interac-logo"
            />
          )}
          <Grid container item justify="center" xs={12} sm>
            <ExternalLink
              to={appDownloadLinks.appStore[localStorageLanguage]}
              newTab
            >
              <DownloadAppStoreIcon
                width={160}
                className={classes.appStoreDownloadIcon}
                style={{ display: "block" }}
              />
            </ExternalLink>
          </Grid>
          <Grid container item justify="center" xs={12} sm>
            <ExternalLink
              to={appDownloadLinks.googlePlay[localStorageLanguage]}
              newTab
            >
              <DownloadGooglePlayIcon
                width={160}
                style={{ display: "block" }}
              />{" "}
            </ExternalLink>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
