const assets = {
  COINMILES_LOGO: require("./logo.png"),
  COINMILES_LOGO_W: require("./logo-white.png"),

  CATEGORY_IMAGE_BEAUTY: require("./beauty.png"),
  CATEGORY_ICON_BEAUTY: require("./beauty-icon.png"),
  CATEGORY_CIRCULAR_ICON_BEAUTY: require("./beauty-icon-circular.png"),

  CATEGORY_IMAGE_FOOD_AND_DRINKS: require("./food-and-drinks.png"),
  CATEGORY_ICON_FOOD_AND_DRINKS: require("./food-and-drinks-icon.png"),
  CATEGORY_CIRCULAR_ICON_FOOD_AND_DRINKS: require("./food-and-drinks-icon-circular.png"),

  CATEGORY_IMAGE_THINGS_TO_DO: require("./things-to-do.png"),
  CATEGORY_ICON_THINGS_TO_DO: require("./things-to-do-icon.png"),
  CATEGORY_CIRCULAR_ICON_THINGS_TO_DO: require("./things-to-do-icon-circular.png"),

  CATEGORY_IMAGE_SERVICES: require("./services.png"),
  CATEGORY_ICON_SERVICES: require("./services-icon.png"),
  CATEGORY_CIRCULAR_ICON_SERVICES: require("./services-icon-circular.png"),

  COINMILES_SQUARE_LOGO: require("./coinmiles-square-logo.png"),
  COINMILES_SQUARE_LOGO_NO_CORNERS: require("./coinmiles-square-logo-no-corners.png"),
  CREDIT_CARD: require("./credit-card.png"),
  DELETE_CARD: require("./delete-card-icon.png"),
  DIRECT_HIT: require("./direct-hit.png"),
  FALLING_COINS: require("./falling-coins.jpg"),
  FALLING_COINS_WIDE: require("./falling-coins-wide.jpg"),
  GIFT_EMOJI: require("./gift-emoji.png"),
  INTERAC_LOGO: require("./interac-logo.png"),
  MAIL_ICON: require("./mail.png"),
  MONEY_BAG: require("./money-bag.png"),
  NO_ENTRY: require("./no-entry.png"),
  PARTY_POPPER: require("./party-popper.png"),
  RIGHT_POINT: require("./right-point.png"),
  ROCK_EMOJI: require("./rock-emoji.png"),
  REFERRAL_LOGO: require("./referral-logo.png"),
  ROCKET_EMOJI: require("./rocket-emoji.png"),
  SHOPPING_BAGS: require("./shopping-bags.png"),
  SPACE_DISCOVERY_ICON: require("./space-discovery.png"),
  STAR_EYES_EMOJI: require("./star-eyes-emoji.png"),
  SUCCESS_CONFETTI: require("./success-confetti.png"),
  SUCCESS_ROCKET: require("./success-rocket.png"),
  WAVE_EMOJI: require("./wave-emoji.png"),
  WITHDRAWAL_ICON: require("./send.png"),

  ALL: require("./star-eyes-emoji.png"),
  BABY_KIDS: require("./baby-kids.png"),
  BEAUTY_WELLNESS: require("./beauty-wellness.png"),
  EDUCATION: require("./education.png"),
  ELECTRONICS: require("./electronics.png"),
  FOOD_GROCERY: require("./food-grocery.png"),
  HOME_GARDEN: require("./home-garden.png"),
  HOTEL_TRAVEL: require("./hotel-travel.png"),
  MENS: require("./mens.png"),
  SPORTS_OUTDOOR: require("./sports-outdoor.png"),
  WOMENS: require("./womens.png"),
};

export { assets as Assets };
