import React from "react";
import PropTypes from "prop-types";

import Amplify from "aws-amplify";

import { initializeAppStartup } from "@utils";

import { Layout } from "@components";
import TopLayout from "./top-layout";

const activeEnv =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "dev";

require("dotenv").config({
  path: `.env.${activeEnv}`,
});

const config = {
  Auth: {
    authenticationFlowType: "USER_PASSWORD_AUTH",
    identityPoolId: process.env.GATSBY_COGNITO_IDENTITY_POOL_ID,
    region: process.env.GATSBY_REGION,
    userPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_COGNITO_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.GATSBY_COGNITO_OAUTH_DOMAIN,
      scope: [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: process.env.GATSBY_SOCIAL_SIGN_IN_REDIRECT_URL,
      redirectSignOut: process.env.GATSBY_SOCIAL_SIGN_OUT_REDIRECT_URL,
      responseType: "code",
    },
  },
};

Amplify.configure(config);

initializeAppStartup();

export const wrapRootElement = ({ element }) => (
  <TopLayout>{element}</TopLayout>
);

export const wrapPageElement = ({ element, props }) => (
  <Layout location={props.location}>{element}</Layout>
);

wrapRootElement.propTypes = {
  element: PropTypes.node,
};
