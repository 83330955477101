export const fr = {
  signUp: "Créer un compte pour continuer",
  signUpWithEmail: "Créer un compte avec un courriel",
  signUpDisclaimer: {
    one: "En me créant un compte, j’accepte les termes et conditions",
    two: " de Coinmiles",
    three: " ainsi que",
    four: " sa politique de confidentialité",
    five: ". Coinmiles n’est disponible qu’au Canada en ce moment.",
  },
  alreadyMember: "Vous avez déjà un compte?",
  modal: {
    earnAt: "Magasinez chez",
    weNeedToKnow: {
      one: "Nous devons savoir qui doit être récompensé.",
      two: "Bonne nouvelle, lorsque vous créez un compte ",
      three: "nous allons automatiquement déposer 0.00005 Bitcoin ",
      four: "dans votre portefeuille.",
    },
    earnFreeBitcoin:
      "Obtenez des bitcoin gratuitement en vous inscrivant à Coinmiles",
    signUpInOneClick: "Connectez-vous en un clic",
    receive: "et recevez automatiquement 0.00005 Bitcoin dans votre compte",
  },
  drawer: {
    letsCheckOut: "Allons-y, regardons les offres",
    aCoolGift: "Voici un cadeau!",
    exploreDeals: {
      one: "Découvrez les offres gratuitement",
      two: "et recevez automatiquement 0.00005 Bitcoin",
    },
  },
  success: {
    thanks: "Bienvenue chez Coinmiles!",
    confirmation: {
      one: "Nous vous avons envoyé un courriel de confirmation à",
      two: "Nous vous avons ré-envoyé le courriel de confirmation à",
      three: "S'il vous plaît confirmer votre courriel",
      four: "Le courriel peut prendre jusqu'à une minute avant d'être reçu.",
      five:
        "S'il vous plaît confirmer votre courriel avant de pouvoir utiliser Coinmiles.",
      six: "N'oubliez pas de vérifier vos courriers indésirables!",
    },
    didntReceive:
      "Vous n’avez pas reçu de courriel (n’oubliez pas de vérifier dans vos courriers indésirables)?",
    sendAgain: " Envoyer de nouveau",
  },
};
