import React, { useState, MouseEvent } from "react";

// Component imports
import { DividerLine, ExternalLink, Link } from "@components";
import { Grid, ListItemText, Menu, MenuItem } from "@material";

// Misc Imports
import { MenuProps, Theme } from "@app-types";
import { useTranslation } from "@i18n";
import { makeStyles, withStyles } from "@styles";
import { AuthUtils } from "@utils";

const useStyles = makeStyles((theme: Theme) => ({
  userMenu: {
    height: 48,
    width: 194,
    paddingTop: 8,
    paddingBottom: 8,
    color: theme.palette.white,
    flexWrap: "nowrap",
    borderRadius: 5,
    border: `1px solid transparent`,
    transitionProperty: "border",
    transitionDuration: "0.4s",
    transitionTimingFunction: "ease-out",
    "&:hover": {
      border: `1px solid ${theme.palette.colors.border.light}`,
      transitionProperty: "border",
      transitionDuration: "0.4s",
      cursor: "pointer",
    },
  },
  initialsBackground: {
    backgroundColor: theme.palette.colors.profile.background,
    width: 36,
    height: 36,
    borderRadius: "50%",
  },
  initialsText: {
    color: theme.palette.white,
    fontSize: 12,
    fontWeight: 600,
  },
  profilePicture: {
    margin: "auto",
    height: 36,
    width: 36,
    borderRadius: "50%",
  },
  userIconContainer: {
    padding: 6,
    paddingRight: 14,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  walletDisplayContainer: {
    color: theme.palette.white,
    minWidth: 98,
  },
  fiatBalanceText: {
    fontSize: 15,
    fontWeight: 600,
    color: theme.palette.black,
  },
  btcBalanceText: {
    display: "flex",
    alignItems: "center",
    fontSize: 13,
    fontWeight: 400,
    marginTop: "-4px",
    color: theme.palette.colors.text.subheader,
  },
  bitcoinIcon: {
    marginRight: 4,
    fill: theme.palette.colors.bitcoin,
  },
  dropdownIcon: {
    textAlign: "center",
    verticalAlign: "middle",
    fill: theme.palette.black,
    marginRight: 8,
  },
}));

const StyledMenu = withStyles((theme) => ({
  paper: {
    marginTop: 8,
    border: `1px solid ${theme.palette.colors.border.light}`,
  },
}))((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    transitionProperty: "background-color",
    transitionDuration: "0.3s",
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
    "&:hover": {
      backgroundColor: `${theme.palette.colors.background.lightGrey} !important`,
      transitionProperty: "background-color",
      transitionDuration: "0.3s",
    },
  },
}))(MenuItem);

type Props = {
  small?: boolean;
};

export const NavbarMenuDropdown: React.FC<Props> = ({ children, small }) => {
  const classes: any = useStyles({});
  const { t } = useTranslation("common");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setAnchorEl(null);
  };

  const onSignOut = (): void => {
    handleMenuClose();
    AuthUtils.signOut();
  };

  return (
    <>
      <Grid
        container
        item
        justify="flex-end"
        alignItems="center"
        className={!small ? classes.userMenu : ""}
        onClick={handleMenuOpen}
      >
        {children}
      </Grid>
      <StyledMenu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        transitionDuration={600}
        onClose={handleMenuClose}
      >
        <Link to="/profile" onClick={handleMenuClose}>
          <StyledMenuItem>
            <ListItemText primary={t("profile")} />
          </StyledMenuItem>
        </Link>
        <Link to="/wallet" onClick={handleMenuClose}>
          <StyledMenuItem>
            <ListItemText primary={t("wallet")} />
          </StyledMenuItem>
        </Link>
        <Link to="/what-i-like" onClick={handleMenuClose}>
          <StyledMenuItem>
            <ListItemText primary={t("whatILike")} />
          </StyledMenuItem>
        </Link>
        <DividerLine />
        <Link to="/" onClick={handleMenuClose}>
          <StyledMenuItem>
            <ListItemText primary={t("shopOnline")} />
          </StyledMenuItem>
        </Link>
        <ExternalLink to="https://www.coinmiles.io/" onClick={handleMenuClose}>
          <StyledMenuItem>
            <ListItemText primary={t("backToMain")} />
          </StyledMenuItem>
        </ExternalLink>
        <DividerLine />
        <StyledMenuItem onClick={onSignOut}>
          <ListItemText primary={t("logOut")} />
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};
