import React, { memo, useCallback } from "react";
import { List, WindowScroller } from "react-virtualized";

import { UserPoolClient, useQuery } from "@apollo";
import { USER_POOL_GET_USER_OFFERS } from "@queries";

// Misc Imports
import { RedirectData, StaticOffer, SignUpData, TermsData } from "@app-types";
import { useMediaQuery, useWindowDimensions } from "@hooks";
import { Offer } from "@models";
import {
  createRedirectData,
  isServerSideRendering,
  ModalHelper,
  OfferCardHelpers,
  openOfferRedirect,
} from "@utils";

// Component imports
import {
  DividerLine,
  GridCardView,
  GridCardRowContainer,
  ListCardView,
  ListCardRowContainer,
} from "@components";

type Props = {
  display: "Cards" | "List";
  offers: StaticOffer[];
  userId: string;
};

export const OfferCardsContainer: React.NamedExoticComponent<Props> = memo(
  function OfferCardsContainerComponent({ display, offers, userId }: Props) {
    if (isServerSideRendering()) {
      return <></>;
    }

    const small: boolean = useMediaQuery("(max-width:959px)");

    const { data } = useQuery<{ userOffers: Offer[] }>(
      USER_POOL_GET_USER_OFFERS,
      {
        client: UserPoolClient,
        fetchPolicy: "cache-only",
        variables: { id: userId },
        onError: (err) => console.warn(`Error fetching user offer IDs: ${err}`),
      },
    );

    const userOfferIds =
      data?.userOffers?.map((offer) => offer.id) || undefined;

    const onRedirectClicked = useCallback((offer: StaticOffer): void => {
      const redirectData: RedirectData = createRedirectData(offer, userId);
      openOfferRedirect(redirectData);
    }, []);

    const onTermsModalClicked = useCallback((offer: StaticOffer): void => {
      const termsModalProps: TermsData = {
        name: offer.advertiserName,
        terms: offer.terms,
        redirectData: createRedirectData(offer, userId),
        openOfferRedirect,
      };
      ModalHelper.open({ modalType: "terms", modalProps: termsModalProps });
    }, []);

    const onSignUpModalClicked = useCallback(
      (offer: StaticOffer): void => {
        if (small) {
          ModalHelper.open({ modalType: "signUpDrawer" });
        } else {
          const signUpModalProps: SignUpData = {
            name: offer.advertiserName,
            logo: offer.advertiserLogoImage,
          };
          ModalHelper.open({
            modalType: "signUp",
            modalProps: signUpModalProps,
          });
        }
      },
      [small],
    );

    const { width, height } = useWindowDimensions();

    // React Virtualized related variables
    const medium: boolean = width <= 1279;
    const rowGap = 17;
    const cardListHeight = 80 + rowGap;
    const cardGridHeight: number = medium ? 300 + rowGap : 358 + rowGap;
    const rowOverScanCount = 4;
    const rowCount: number =
      display === "List"
        ? offers.length
        : OfferCardHelpers.getRowsAmount(offers.length, width);
    const maxItemsPerRow: number =
      display === "List" ? 1 : OfferCardHelpers.getMaxItemsAmountPerRow(width);

    // Renders one row of cards to be rendered by React Virtualized.
    // In Cards mode, number of cards per row is calculated based on screen width.
    // In List view, one row is one card.
    const OfferCardRow = ({ index, style }): JSX.Element => {
      const offersForRow: StaticOffer[] = OfferCardHelpers.generateIndexesForRow(
        index,
        maxItemsPerRow,
        offers.length,
      ).map((offerIndex: number) => offers[offerIndex]);

      const lastIndex: number = offers.length - 1;

      const mapOfferCardComponentsToRow = (offer: StaticOffer): JSX.Element => {
        const favourited: boolean = userOfferIds?.includes(offer.id);

        if (display === "Cards") {
          return (
            <React.Fragment key={offer.id}>
              <GridCardView
                offer={offer}
                userId={userId}
                favourited={favourited}
                onRedirectClicked={onRedirectClicked}
                onTermsModalClicked={onTermsModalClicked}
                onSignUpModalClicked={onSignUpModalClicked}
              />
            </React.Fragment>
          );
        }

        if (display === "List") {
          return (
            <React.Fragment key={offer.id}>
              <ListCardView
                offer={offer}
                userId={userId}
                favourited={favourited}
                onRedirectClicked={onRedirectClicked}
                onTermsModalClicked={onTermsModalClicked}
                onSignUpModalClicked={onSignUpModalClicked}
              />
              {index !== lastIndex && <DividerLine />}
            </React.Fragment>
          );
        }
      };

      return display === "List" ? (
        <ListCardRowContainer
          key={`offers-cards-row-${index + 1}`}
          style={style}
        >
          {offersForRow.map(mapOfferCardComponentsToRow)}
        </ListCardRowContainer>
      ) : (
        <GridCardRowContainer
          key={`offers-cards-row-${index + 1}`}
          style={style}
        >
          {offersForRow.map(mapOfferCardComponentsToRow)}
        </GridCardRowContainer>
      );
    };

    return (
      <WindowScroller>
        {({ scrollTop }) => (
          <List
            autoHeight
            height={height}
            rowCount={rowCount}
            rowHeight={display === "List" ? cardListHeight : cardGridHeight}
            rowRenderer={OfferCardRow}
            overscanRowCount={rowOverScanCount}
            scrollTop={scrollTop}
            width={width}
            style={{ outline: "none", width: "100%" }}
          />
        )}
      </WindowScroller>
    );
  },
);
