import { Auth } from "aws-amplify";
import { trim, isEmail, matches } from "validator";

import { currentCountryVar, currentUserVar, UserPoolClient } from "@apollo";
import { USER_POOL_GET_FINANCE_DATA } from "@queries";

// Misc imports
import { CognitoUser, FinanceData } from "@app-types";
import { User } from "@models";
import {
  fetchSignInData,
  getAffiliateServiceArea,
  LocaleUtils,
  ModalHelper,
  navigate,
} from "@utils";

export class AuthUtils {
  static isValidUsername(username) {
    const trimmedUsername = trim(username);
    return isEmail(trimmedUsername);
  }

  static isValidPassword(password) {
    const trimmedPassword = trim(password);
    return (
      trimmedPassword.length >= 8 &&
      matches(
        trimmedPassword,
        /^.*(?=.{0,})(?=.*[a-z])(?=.*[A-Z])(?=.*[\d\W])(?=.*[!@#\$%&/=?_\.,:;-\\]).*$/,
      )
    );
  }

  static signIn = async ({
    emailAddress,
    password,
    referrer,
    updateUserLocaleIfDifferent = null,
  }: {
    emailAddress: string;
    password: string;
    referrer?: "wallet-sign-in" | undefined;
    updateUserLocaleIfDifferent: (user: User) => void;
  }) => {
    const cognitoUser: CognitoUser = await Auth.signIn({
      username: emailAddress,
      password,
    });

    const currencyCode = getAffiliateServiceArea(
      localStorage.getItem("userCountry"),
    ).currencyCode;

    const user = (await fetchSignInData(cognitoUser, currencyCode)) || null;

    if (user) {
      const { userCountry } = await LocaleUtils.setSignInLocale();

      updateUserLocaleIfDifferent(user);

      UserPoolClient.query<FinanceData>({
        query: USER_POOL_GET_FINANCE_DATA,
        variables: { convertedCurrency: userCountry.currencyCode },
      });

      // Assigns current country and user to local state Reactive Vars then redirects
      navigate(referrer === "wallet-sign-in" ? "/wallet" : "/", {
        state: { signIn: true },
      });
      currentCountryVar(userCountry);
      currentUserVar(user);
    } else {
      await Auth.signOut();
      currentUserVar(null);
      navigate("/sign-in", {
        state: { signInError: true },
      });
    }
  };

  static signOut = async (): Promise<void> => {
    currentUserVar(null);

    const localStorageCountry: string =
      localStorage.getItem("userCountry") || "en";
    const localStorageLanguage: string =
      localStorage.getItem("i18nextLng") || "en";

    localStorage.clear();

    localStorage.setItem("userCountry", localStorageCountry);
    localStorage.setItem("i18nextLng", localStorageLanguage);

    await Auth.signOut();

    navigate("/");
  };
}
