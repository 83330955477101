import { gql } from "@apollo/client";

export const USER_POOL_GET_SIGN_IN_DATA = gql`
  query GetSignInData($id: ID!, $currency: String) {
    userOfferIds: getUserOffers(id: $id) {
      id
    }
    linkedCards: getLinkedCards(id: $id) {
      id
      brand
      expMonth
      expYear
      externalId
      last4
    }
    usdRate: getExchangeRate(initialCurrency: "USD", convertedCurrency: "CAD") {
      exchangeRate
      price
    }
    wallet: getWallet(id: $id, currency: $currency) {
      userId
      balanceIncludingPendingInFiat {
        currency
        value
      }
      balanceNotIncludingFrozen {
        btc {
          value
        }
      }
      balanceIncludingFrozen {
        fiat {
          currency
          value
        }
      }
      transactions {
        id
        amount {
          fiat {
            currency
            value
          }
          btc {
            currency
            value
          }
        }
        createdAt
        externalRef {
          type
          data {
            advertiserName
            amount {
              fiat {
                value
              }
            }
            campaign {
              account {
                images {
                  original
                }
                industry
                name
                useLogoImage
              }
            }
            externalData {
              offer {
                advertiserLogo
              }
            }
            logo
            referredUserFirstName
          }
        }
        type
      }
    }
  }
`;
