import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
  Reference,
} from "@apollo/client";
import { RetryLink } from "apollo-link-retry";
import { persistCacheSync } from "apollo3-cache-persist";

import { Auth } from "aws-amplify";
import { createAuthLink, AUTH_TYPE, AuthOptions } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";

import { isServerSideRendering } from "@utils";

global.fetch = require("isomorphic-fetch");

const activeEnv =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "dev";

require("dotenv").config({
  path: `.env.${activeEnv}`,
});

interface AuthLink {
  url: string;
  region: string;
  auth: AuthOptions;
  disableOffline?: boolean;
}

const awsConfig = {
  graphQlEndpoint: process.env.GATSBY_APP_SYNC_GRAPHQL_ENDPOINT,
  region: process.env.GATSBY_REGION,
};

const userPoolAuthConfig: AuthLink = {
  url: awsConfig.graphQlEndpoint,
  region: awsConfig.region,
  disableOffline: true,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => {
      try {
        return (await Auth.currentSession()).getIdToken().getJwtToken();
      } catch (err) {
        console.error(`Error fetching JWT: ${err}`);
      }
    },
  },
};

const cache = new InMemoryCache({
  typePolicies: {
    Wallet: {
      keyFields: ["userId"],
    },
    WalletExternalRefs: {
      keyFields: false,
    },
    Query: {
      fields: {
        getLinkedCards: {
          merge(_existing, incoming: Reference[]) {
            if (incoming === []) {
              return [];
            } else {
              return incoming;
            }
          },
        },
      },
    },
  },
});

export const initCache = (): void => {
  if (!isServerSideRendering()) {
    persistCacheSync({
      cache,
      storage: window.localStorage,
      key: "coinmiles-cache-persist",
    } as any);
  }
};

const createLink = (config: AuthLink): ApolloLink => {
  const httpLink = createHttpLink({ uri: config.url });

  return from([
    // Retries network connections automatically if they fail
    new RetryLink(),
    // AppSync connection links
    createAuthLink({
      url: config.url,
      region: config.region,
      auth: config.auth,
    }) as any,
    createSubscriptionHandshakeLink(config.url as any, httpLink as any),
  ]);
};

const UserPoolClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: createLink(userPoolAuthConfig),
  cache,
  connectToDevTools: true,
});

export { UserPoolClient };
