// Returns a USD dollar amount with $ prefix, comma-separated 1,000's, and 2 decimal places.
// Note: this is a simplified version of the Amount class/model in common-core, and will require
// expansion when implementing new currencies.

export function convertToFormattedDollars(
  value: number,
  options: object = { style: "currency", currency: "USD" }
): string {
  var num_parts = value.toFixed(2).toString().split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${num_parts.join(".")}`;
}
