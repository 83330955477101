import React from "react";

// Component imports
import { Grid, Typography } from "@material";

// Misc Imports
import { Theme } from "@app-types";
import { Assets } from "@assets";
import { useTranslation } from "@i18n";
import { makeStyles } from "@styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    position: "absolute",
    flex: 1,
  },
  coinmilesLogo: {
    height: 120,
    width: 120,
    [theme.breakpoints.down("xs")]: {
      height: 80,
      width: 80,
    },
  },
  loadingText: {
    marginTop: theme.layout.spacing * 1,
    color: theme.palette.white,
    fontWeight: 400,
    fontSize: 22,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
}));

export function SocialAuthModal() {
  const classes: any = useStyles({});
  const { t } = useTranslation("common");

  return (
    <Grid
      className={classes.root}
      container
      justify="center"
      alignItems="center"
      direction="column"
    >
      <img
        className={classes.coinmilesLogo}
        src={Assets.COINMILES_SQUARE_LOGO_NO_CORNERS}
        alt="coinmiles-square-logo"
      />
      <Typography className={classes.loadingText}>{t("loading")}</Typography>
    </Grid>
  );
}
