import React from "react";

// Misc Imports
import { Theme } from "@app-types";
import { User } from "@models";
import { makeStyles } from "@styles";

// Component imports
import { ProfilePhoto } from "@components";
import { Grid, Skeleton, Typography } from "@material";

import { NavbarMenuDropdown } from "./navbar-menu-dropdown";

const useStyles = makeStyles((theme: Theme) => ({
  initialsBackground: {
    cursor: "pointer",
    backgroundColor: theme.palette.colors.profile.background,
    width: 36,
    height: 36,
    borderRadius: "50%",
  },
  initialsText: {
    color: theme.palette.white,
    fontSize: 12,
    fontWeight: 600,
  },
  profilePicContainer: {
    cursor: "pointer",
    height: 36,
    width: 36,
  },
}));

type Props = {
  user: User;
  loading: boolean;
};

export function NavbarMenuMobile({ user, loading }: Props) {
  const classes: any = useStyles({});

  const getUserInitials = (firstName: string, lastName: string) =>
    firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();

  const MenuSkeleton = () => (
    <Grid
      container
      justify="flex-end"
      alignItems="center"
      className={classes.profilePicContainer}
    >
      <Skeleton variant="circle" animation="wave" height={36} width={36} />
    </Grid>
  );

  const MenuLoaded = ({ user }) => {
    return (
      <>
        {user.profilePhotoUrl ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.profilePicContainer}
          >
            <ProfilePhoto profilePhotoUrl={user.profilePhotoUrl} size={36} />
          </Grid>
        ) : (
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.initialsBackground}
          >
            <Typography className={classes.initialsText}>
              {getUserInitials(user.firstName, user.lastName)}
            </Typography>
          </Grid>
        )}
      </>
    );
  };

  return (
    <NavbarMenuDropdown small>
      {loading ? <MenuSkeleton /> : <MenuLoaded user={user} />}
    </NavbarMenuDropdown>
  );
}
