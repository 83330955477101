import React, { MouseEvent } from "react";

import { useMutation, UserPoolClient } from "@apollo";
import {
  MUTATION_CREATE_USER_OFFER,
  MUTATION_DELETE_USER_OFFER,
  USER_POOL_GET_USER_OFFERS,
} from "@queries";

import { Offer } from "@models";

// Component imports
import { HeartIconFilled, HeartIconOutline } from "@icons";

type Props = {
  userId: string;
  offerId: string;
  favourited: boolean;
  fill: string;
  size: number;
};

export const FavouritedHeart = ({
  userId,
  offerId,
  favourited,
  size,
  fill,
}: Props) => {
  const [createUserOffer] = useMutation(MUTATION_CREATE_USER_OFFER, {
    client: UserPoolClient,
    variables: { offerId, userId },
    optimisticResponse: {
      createUserOffer: {
        __typename: "Offer",
        id: offerId,
      },
    },
    update(cache, { data: { createUserOffer } }) {
      const { userOffers } = cache.readQuery<{ userOffers: Offer[] }>({
        query: USER_POOL_GET_USER_OFFERS,
        variables: { id: userId },
      });

      cache.writeQuery<{ userOffers: Offer[] }>({
        query: USER_POOL_GET_USER_OFFERS,
        variables: { id: userId },
        data: {
          userOffers: userOffers.length
            ? [...userOffers, new Offer(createUserOffer)]
            : [new Offer(createUserOffer)],
        },
      });
    },
    onError: (err) => console.error(`Error creating user offer: ${err}`),
  });

  const [deleteUserOffer] = useMutation(MUTATION_DELETE_USER_OFFER, {
    client: UserPoolClient,
    variables: { offerId, userId },
    optimisticResponse: {
      deleteUserOffer: offerId,
    },
    update(cache, { data: { deleteUserOffer } }) {
      const { userOffers } = cache.readQuery<{ userOffers: Offer[] }>({
        query: USER_POOL_GET_USER_OFFERS,
        variables: { id: userId },
      });

      cache.writeQuery({
        query: USER_POOL_GET_USER_OFFERS,
        variables: { id: userId },
        data: {
          userOffers: userOffers.filter(
            (userOffer) => userOffer.id !== deleteUserOffer,
          ),
        },
      });
    },
    onError: (err) => console.error(`Error deleting user offer: ${err}`),
  });

  const handleFavouriteSubmit = async (): Promise<void> => {
    if (favourited) {
      deleteUserOffer();
    } else {
      createUserOffer();
    }
  };

  const handleFavoritedClicked = (event: MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    if (event.button === 0) {
      handleFavouriteSubmit();
    }
  };

  return (
    <div onMouseDown={(event) => userId && handleFavoritedClicked(event)}>
      {favourited ? (
        <HeartIconFilled fill={fill} width={size} />
      ) : (
        <HeartIconOutline fill={fill} width={size} />
      )}
    </div>
  );
};
