import React from "react";

// Misc Imports
import { AffiliateServiceArea, Theme } from "@app-types";
import { Assets, couponCategories } from "@assets";
import { useMediaQuery } from "@hooks";
import { useTranslation } from "@i18n";
import { Amount, WalletTransaction } from "@models";
import { makeStyles } from "@styles";
import { convertToFormattedDollars, ModalHelper } from "@utils";
import { useReactiveVar, currentCountryVar } from "@apollo";

// Component imports
import { DividerLine } from "@components";
import { Grid, Paper, Typography } from "@material";

import { WalletTransactionCard } from "../wallet-transaction-card";

const useStyles = makeStyles((theme: Theme) => ({
  walletActivity: {
    backgroundColor: "white",
    border: `1px solid #EBEBEB`,
    width: "100%",
    padding: 24,
    minHeight: 419,
  },
  transactionHeader: {
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-end",
    },
  },
  balanceHeader: {
    fontSize: 24,
    fontWeight: 500,
  },
  missingTransactionsContainer: {
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },
  missingTransactions: {
    fontSize: 14,
    fontWeight: 400,
  },
  transactionContainer: { minHeight: 285 },
  moneyBagContainer: {
    height: 88,
    width: 88,
    borderRadius: "50%",
    border: "1px solid #9e9e9e",
    backgroundColor: "#FAFAFA",
  },
  moneyBag: {
    width: 64,
  },
  noRewardsHeader: {
    fontSize: 20,
    color: "black",
    marginTop: 8,
    marginBottom: 8,
  },
  noRewardsText: {
    fontSize: 18,
    color: "#393939",
  },
}));

type Props = {
  transactions: WalletTransaction[];
};

export function WalletActivity({ transactions }: Props) {
  const classes: any = useStyles({});
  const small: boolean = useMediaQuery("(max-width:959px)");
  const { t } = useTranslation("wallet");

  const handleOpenMissingTransactionsModal = (): void => {
    ModalHelper.open({ modalType: "missingTransactions" });
  };

  const country = useReactiveVar(currentCountryVar);

  const interacFee = 3.99;

  const transactionCards = transactions.map(
    (transaction: WalletTransaction, index: number) => {
      const iso8016Time = new Date(transaction.createdAt);
      // "en-GB" locale gives desired date format, eg. DD mmm YYYY
      const createdAt = iso8016Time.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      const isInterac: boolean = transaction.externalRef.type === "Interac";
      const isAffiliate: boolean = transaction.externalRef.type === "Affiliate";
      const isWithdrawal: boolean = isAffiliate
        ? false
        : transaction.type === "Withdrawal";
      const isPromotion: boolean =
        transaction.externalRef.type === "InitialRewards" ||
        transaction.externalRef.type === "AdHocRewards";
      const isReferral: boolean = transaction.externalRef?.type === "Referral";

      const fiat =
        transaction.amount.fiat && new Amount(transaction.amount.fiat);
      const externalRefFiat =
        transaction?.externalRef?.data?.amount?.fiat &&
        new Amount(transaction.externalRef.data.amount.fiat);
      const btc =
        transaction.amount.btc &&
        transaction.amount.btc.value != 0 &&
        transaction.amount.btc.currency != null &&
        new Amount(transaction.amount.btc);

      // get title
      let itemTitle: string;
      if (isPromotion) {
        if (transaction.externalRef.type === "InitialRewards") {
          itemTitle = t("welcomeBonus");
        }
        if (transaction.externalRef.type === "AdHocRewards") {
          itemTitle = t("bonus");
        }
      } else if (isAffiliate) {
        itemTitle = transaction.externalRef.data.advertiserName;
      } else if (isWithdrawal) {
        itemTitle = isInterac ? t("interacTransfer") : t("bitcoinSent");
      } else if (isReferral) {
        itemTitle = `${transaction.externalRef.data.referredUserFirstName} ${t(
          "referralSignUp",
        )}`;
      } else {
        itemTitle = transaction?.externalRef?.data?.campaign?.account?.name;
      }

      // get logo/image
      let imageSource: string;
      if (isPromotion) {
        imageSource = transaction.externalRef.data.logo;
      } else if (isAffiliate) {
        imageSource = transaction?.externalRef?.data?.externalData?.offer
          ? transaction.externalRef.data.externalData.offer.advertiserLogo
          : "";
      } else {
        if (isInterac) {
          imageSource = Assets.INTERAC_LOGO;
        } else if (isWithdrawal) {
          imageSource = Assets.WITHDRAWAL_ICON;
        } else if (isReferral) {
          imageSource = Assets.REFERRAL_LOGO;
        } else {
          let account = transaction.externalRef.data.campaign.account;
          imageSource = account.useLogoImage
            ? account.images[0].original
            : couponCategories.find(
                (category) => category.name === account.industry,
              )?.icon;
        }
      }

      // get string amount (w/ currency)
      let amount: string;
      if (isPromotion) {
        const currency: string = btc.currency;
        amount = `${btc.unscaledValue.toFixed(6)} ${currency}`;
      } else if (isAffiliate) {
        let currency: string = fiat.currency ?? "CAD";
        if (
          (country.countryCode === "US" && currency === "USD") ||
          (country.countryCode === "CA" && currency === "CAD")
        )
          currency = "";
        amount = `${currency} $${fiat?.unscaledValue?.toFixed(2)}`;
      } else {
        if (isWithdrawal) {
          if (isInterac) {
            let currency = "CAD";
            if (country.countryCode === "CA" && currency === "CAD")
              currency = "";
            amount = `${currency} $${Math.abs(
              externalRefFiat.unscaledValue - interacFee,
            ).toFixed(2)}`;
          } else {
            amount = `${btc.unscaledValue.toFixed(6)} BTC`;
          }
        } else if (isReferral) {
          amount = null;
        } else {
          let currency: string = fiat.currency ?? "CAD";
          if (
            (country.countryCode === "US" && currency === "USD") ||
            (country.countryCode === "CA" && currency === "CAD")
          )
            currency = "";
          amount = `${currency} $${convertToFormattedDollars(
            externalRefFiat.unscaledValue,
          )}`;
        }
      }

      // get BTC amount string, if the transaction has BTC allocated
      let btcAmount: string = "";
      if (!isWithdrawal && !isPromotion && btc) {
        btcAmount = `${btc.unscaledValue.toFixed(8)} BTC`;
      }

      return (
        <div key={index}>
          <WalletTransactionCard
            company={itemTitle}
            logo={imageSource}
            date={createdAt}
            amount={amount}
            btcAmount={btcAmount}
            isWithdrawal={isWithdrawal}
          />
          {!!(
            index !== transactions.length - 1 || transactions.length === 1
          ) && <DividerLine />}
        </div>
      );
    },
  );

  return (
    <Paper className={classes.walletActivity}>
      <Grid container direction="column">
        <Grid
          container
          item
          direction="row"
          justify="space-between"
          lg={12}
          className={classes.transactionHeader}
        >
          <Grid item xs={12} sm={12} md={5} lg={6}>
            {" "}
            <Typography className={classes.balanceHeader}>
              {t("myActivity")}
            </Typography>
          </Grid>
          <Grid
            container
            item
            alignItems="flex-start"
            sm={12}
            md={7}
            lg={6}
            className={classes.missingTransactionsContainer}
          >
            {" "}
            <Typography className={classes.missingTransactions}>
              {t("missing")}{" "}
              <strong
                onClick={handleOpenMissingTransactionsModal}
                style={{ cursor: "pointer" }}
              >
                {t("clickHere")}
              </strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            item
            direction="column"
            justify={transactions.length === 0 ? "center" : "flex-start"}
            alignItems={transactions.length === 0 ? "center" : "stretch"}
            lg={12}
            className={classes.transactionContainer}
          >
            {transactions.length === 0 ? (
              <>
                <Grid
                  container
                  item
                  alignItems="center"
                  justify="center"
                  className={classes.moneyBagContainer}
                >
                  <img
                    src={Assets.MONEY_BAG}
                    className={classes.moneyBag}
                    alt="money-bag-emoji"
                  />
                </Grid>
                <Typography className={classes.noRewardsHeader}>
                  {t("noRewards")}
                </Typography>
                <Typography className={classes.noRewardsText}>
                  {t("startVisiting")}
                </Typography>
              </>
            ) : (
              <div style={{ width: "100%" }}>{transactionCards}</div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
