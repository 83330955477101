import {Country, Language} from "@app-types";
import {CanadaFlagIcon} from "@icons";
// import {USFlagIcon} from "../components/icons";

const countryOptions: Country[] = [
  {code: "CA", fullName: "Canada", currency: "CAD", flag: CanadaFlagIcon},
  // {code: "US", fullName: "USA", currency: "USD", flag: USFlagIcon},
];

const languageOptions: Language[] = [
  {code: "en", fullName: "English", flag: CanadaFlagIcon},
  {code: "fr", fullName: "Français", flag: CanadaFlagIcon},
];

export {countryOptions, languageOptions};
