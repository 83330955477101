import { gql } from "@apollo/client";

export const SCREEN_GET_PROFILE_LINKED_CARDS_DATA = gql`
  query ScreenGetProfileData($id: ID!) {
    linkedCards: getLinkedCards(id: $id) {
      id
      brand
      expMonth
      expYear
      externalId
      last4
    }
  }
`;
