import {RedirectData} from "@app-types";
import {isServerSideRendering} from "@utils";

export const openOfferRedirect = (redirectData: RedirectData): void => {
  if (isServerSideRendering()) {
    return;
  }

  if (localStorage.getItem("redirectData") !== null) {
    localStorage.removeItem("redirectData");
  }

  localStorage.setItem("redirectData", JSON.stringify(redirectData));

  window.open("/redirect", "_blank");
};
