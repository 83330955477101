import axios, { AxiosResponse } from "axios";

// Function calls the IP stack API to detect user's country code
export const getUserCountry = async (): Promise<string> => {
  try {
    const userLocationData: AxiosResponse = await axios.get(
      `http://api.ipstack.com/check?access_key=${process.env.GATSBY_IP_STACK_API_KEY}&fields=country_code`,
    );

    const {
      country_code: country,
    }: { country_code: string } = userLocationData.data;

    return country;
  } catch (err) {
    console.error(
      `An error occurred attempting to fetch user data via IP Stack: ${err}`,
    );
  }
};
