import {en} from "./translations.en";
import {fr} from "./translations.fr";

export const translations = {
  en: {
    navbar: en,
  },
  fr: {
    navbar: fr,
  },
};
