import React from "react";

// Component imports
import { Theme } from "@app-types";
import { useTranslation } from "@i18n";
import { BitcoinIcon } from "@icons";
import { Grid, Paper, Typography } from "@material";
import { makeStyles } from "@styles";

const useStyles = makeStyles((theme: Theme) => ({
  payoutBanner: {
    display: "inline",
    height: 31,
    width: "auto",
    borderRadius: "2px",
    backgroundColor: theme.palette.colors.background.lightGrey,
    padding: "2px 0px 1px 0px",
    [theme.breakpoints.down("xs")]: {
      height: 24.8,
    },
  },
  bitcoinIcon: {
    marginLeft: 6,
    marginTop: 5,
    marginBottom: 7,
    fill: theme.palette.colors.bitcoin,
    width: 18,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 5,
      marginTop: 3,
      width: 14.4,
    },
  },
  text: {
    position: "relative",
    marginLeft: 6,
    marginRight: 6,
    fontWeight: 500,
    bottom: 1,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 5,
      marginRight: 5,
    },
  },
}));

type Props = {
  payoutText: string;
  className?: any;
  style?: any;
  onClick?: any;
  smallPhone?: boolean;
};

export function PayoutBanner({
  payoutText,
  style,
  onClick,
  smallPhone,
}: Props) {
  const classes: any = useStyles({});
  const { t } = useTranslation("common");

  return (
    <Paper className={classes.payoutBanner} style={style} onClick={onClick}>
      <Grid container alignItems="center">
        <BitcoinIcon className={classes.bitcoinIcon} />
        <Typography
          className={classes.text}
          style={smallPhone ? { fontSize: 11 } : { fontSize: 14 }}
        >
          {payoutText ? `${t("earn")} ${payoutText}` : `${t("signInToView")}`}
        </Typography>
      </Grid>
    </Paper>
  );
}
