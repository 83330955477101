import React from "react";

import { useReactiveVar, currentUserVar } from "@apollo";

import { Footer, Navbar, RootModal } from "@components";

export const Layout = ({ children, location }) => {
  if (
    location.pathname === "/redirect/" ||
    location.pathname === "/social-sign-in/"
  ) {
    return <>{children}</>;
  }

  const user = useReactiveVar(currentUserVar);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Navbar userId={user?.id} />
      <RootModal />
      {children}
      <Footer />
    </div>
  );
};
