import React from "react";
import PropTypes from "prop-types";
import { withPrefix } from "gatsby";
import { Helmet } from "react-helmet";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";

import { ApolloProvider, UserPoolClient } from "../../src/apollo";

import { useIsClient } from "../../src/hooks";
import { i18n, I18nextProvider } from "../../src/i18n";
import { GatsbyTheme as theme } from "./theme";

const HeadComponent = () => (
  <Helmet>
    <meta
      name="viewport"
      content="minimum-scale=1, initial-scale=1, width=device-width"
    />
    <meta name="theme-color" content="#000000" />
    <meta
      name="description"
      content="Coinmiles lets you earn Bitcoin when you shop online or in-store. Whether it’s to get a Pizza, to travel or to fashion up, there’s always a way to earn. Hundreds of top brands are available. Shop on our webapp."
    />

    {/* Open Graph information for sharing through socials */}
    <meta property="og:url" content="https://app.coinmiles.io" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Coinmiles" />
    <meta
      property="og:description"
      content="The ultimate reward app. Automatically receive Bitcoin when you shop online. Easier & more rewarding than points."
    />
    <meta property="fb:app_id" content="2327344913970225" />
    <meta
      property="og:image"
      content="https://app.coinmiles.io/coinmiles-open-graph.jpg"
    />
    <meta
      property="og:image:secure_url"
      content="https://app.coinmiles.io/coinmiles-open-graph.jpg"
    />
    {/* End Open Graph code */}

    <script src={withPrefix("prebid-ads.js")} type="text/javascript" />
    <title>Coinmiles</title>
  </Helmet>
);

export default function TopLayout({ children }) {
  const { isClient, key } = useIsClient();

  return (
    <div key={key}>
      <HeadComponent />
      <ApolloProvider client={UserPoolClient}>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {!isClient ? null : children}
          </ThemeProvider>
        </I18nextProvider>
      </ApolloProvider>
    </div>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
