import {en} from "./translations.en";
import {fr} from "./translations.fr";

export const translations = {
  en: {
    signUp: en,
  },
  fr: {
    signUp: fr,
  },
};
