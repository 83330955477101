import { gql } from "@apollo/client";

export const SCREEN_GET_NAVBAR_DATA = gql`
  query ScreenGetNavbarData($id: ID!, $currency: String) {
    wallet: getWallet(id: $id, currency: $currency) {
      userId
      balanceIncludingPendingInFiat
      balanceNotIncludingFrozen {
        btc {
          value
        }
      }
      balanceIncludingFrozen {
        fiat {
          value
        }
      }
      transactions {
        amount {
          fiat {
            currency
          }
          btc {
            currency
            value
          }
        }
      }
    }
    usdRate: getExchangeRate(initialCurrency: "USD", convertedCurrency: "CAD") {
      exchangeRate
    }
  }
`;
