import { graphql, useStaticQuery } from "gatsby";

import { currentCountryVar, useReactiveVar } from "@apollo";

import { StaticOffer } from "@app-types";
import { Offer } from "@models";
import { createPayoutText } from "@utils";

interface ProcessedOffer extends Offer {
  backgroundImage: any;
  advertiserLogoImage: any;
}

interface StaticOfferQueryResults {
  coinmiles: { getAllOffers: ProcessedOffer[] };
}

export const useOffers = () => {
  const {
    coinmiles: { getAllOffers: offers },
  } = useStaticQuery<StaticOfferQueryResults>(graphql`
    {
      coinmiles {
        getAllOffers {
          id
          advertiserName
          affiliateNetwork
          trackingUrl
          advertiserUrl
          advertiserDescription
          terms
          displayOrder
          activeDate
          payoutType
          categories
          payoutValue
          divisor
          advertiserLogo
          backgroundImageUrl
          serviceArea
          backgroundImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 490, maxHeight: 358, quality: 85) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          advertiserLogoImage {
            localFile {
              childImageSharp {
                fluid(maxHeight: 110, maxWidth: 110, quality: 85) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  `);

  const currentCountry = useReactiveVar(currentCountryVar);

  const mappedStaticOffers: StaticOffer[] = offers
    .filter((offer: ProcessedOffer) =>
      offer.serviceArea.includes(currentCountry?.id),
    )
    .map((offer: ProcessedOffer) => ({
      id: offer.id,
      advertiserName: offer.advertiserName,
      affiliateNetwork: offer.affiliateNetwork,
      trackingUrl: offer.trackingUrl,
      advertiserUrl: offer.advertiserUrl,
      advertiserDescription: offer.advertiserDescription,
      terms: offer.terms,
      categories: offer.categories,
      displayOrder: offer.displayOrder,
      activeDate: offer.activeDate,
      payoutValue: offer.payoutValue,
      payoutText: createPayoutText(offer),
      backgroundImage:
        offer?.backgroundImage?.localFile?.childImageSharp?.fluid || null,
      advertiserLogoImage:
        offer?.advertiserLogoImage?.localFile?.childImageSharp?.fluid || null,
    }));

  return mappedStaticOffers;
};
