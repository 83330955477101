import React from "react";

// Component imports
import {Grid, Paper, Typography} from "@material";
import {BitcoinIcon} from "@icons";

// Misc Imports
import {Theme} from "@app-types";
import {makeStyles} from "@styles";

const useStyles = makeStyles((theme: Theme) => ({
  cashbackBanner: {
    display: "inline",
    height: 31,
    width: 167,
    borderRadius: "2px",
    backgroundColor: theme.palette.background.lightGrey,
    padding: "2px 0px 1px 0px",
  },
  bitcoinIcon: {
    marginLeft: 7,
    marginTop: 5,
    marginBottom: 7,
    fill: theme.palette.colors.bitcoin,
    width: 18,
  },
  text: {
    position: "relative",
    marginLeft: 9,
    fontSize: 16,
    fontWeight: 400,
    bottom: 1,
    textAlign: "center",
  },
}));

type Props = {
  btcBalance: number;
};

export function BtcBalanceDisplay(props: Props) {
  const classes: any = useStyles({});

  const {btcBalance} = props;

  return (
    <Paper className={classes.cashbackBanner}>
      <Grid container alignItems="center">
        <BitcoinIcon className={classes.bitcoinIcon} />
        <Typography className={classes.text}>
          {(btcBalance / 100000000).toFixed(8)} BTC
        </Typography>
      </Grid>
    </Paper>
  );
}
