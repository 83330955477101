import React from "react";

import { Grid, Typography } from "@material";

// Misc Imports
import { Theme } from "@app-types";
import { Assets } from "@assets";
import { useTranslation } from "@i18n";
import { makeStyles } from "@styles";

const useStyles = makeStyles((theme: Theme) => ({
  warningIcon: {
    height: 16,
    width: 16,
    marginBottom: 2,
  },
  bannerBackground: {
    width: "100%",
    height: 50,
    backgroundColor: theme.palette.colors.adBlockBanner,
  },
  boldText: {
    color: theme.palette.black,
    fontSize: 16,
    fontWeight: 500,
    marginLeft: theme.layout.spacing,
  },
  text: {
    color: theme.palette.black,
    fontSize: 16,
    marginLeft: theme.layout.spacing,
    marginRight: theme.layout.spacing,
  },
}));

export function AdblockBanner(): JSX.Element {
  const classes: any = useStyles({});
  const { t } = useTranslation("navbar");

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.bannerBackground}
    >
      <img
        className={classes.warningIcon}
        src={Assets.NO_ENTRY}
        alt="warning-icon"
      />
      <Typography className={classes.boldText}>
        {t("adblockDetected.bold")}
      </Typography>
      <Typography className={classes.text}>
        {t("adblockDetected.message")}
      </Typography>
      <img
        className={classes.warningIcon}
        src={Assets.NO_ENTRY}
        alt="warning-icon"
      />
    </Grid>
  );
}
