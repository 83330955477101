import { gql } from "@apollo/client";

export const USER_POOL_GET_FINANCE_DATA = gql`
  query GetFinanceData($convertedCurrency: String!) {
    btcRate: getExchangeRate(
      initialCurrency: "XBT"
      convertedCurrency: $convertedCurrency
    ) {
      exchangeRate
      price
    }
    priceHistory: getPriceHistory(
      initialCurrency: "XBT"
      convertedCurrency: "CAD"
    )
  }
`;
