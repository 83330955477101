export const fr = {
  filtersHeader: {
    filters: "Filtres",
    more: "+ Plus",
    orderBy: "Commandé:",
    categories: {
      ALL: "Toutes les offres",
      MENS: "Hommes",
      WOMENS: "Femmes",
      SPORTS_OUTDOOR: "Sports et Plein Air",
      HOME_GARDEN: "Maison & Jardin",
      ELECTRONICS: "Électronique",
      HOTEL_TRAVEL: "Hôtel & Voyage",
      BEAUTY_WELLNESS: "Beauté et bien-être",
      BABY_KIDS: "Bébé et enfants",
      FOOD_GROCERY: "Alimentation et épicerie",
      EDUCATION: "Éducation",
    },
    orderOptions: {
      Featured: "En vedette",
      "Highest cashback": "Cashback",
      "A-Z": "A-Z",
      "Z-A": "Z-A",
      "New offers": "Nouvelles offres",
    },
    displayOptions: {
      Cards: "Cartes",
      List: "Liste",
    },
    successModal: {
      welcome: "Bienvenue chez Coinmiles!",
      youGotBtc: "Vous venez de recevoir 0.0005 BTC",
      youCanNowOfficially:
        "Vous pouvez officiellement dire que vous avez reçu des Bitcoin gratuitement. Fantastique! Continuez à en gagner en utilisant Coinmiles pour vos achats en ligne.",
      great: "Génial!",
    },
  },
  redirect: {
    expiredOffer: "Offre expirée",
    redirecting: "Nous vous redirigeons vers...",
    activated: "Activé!",
    happyShopping: "Bon magasinage!",
    oops: "Oups! Cette offre n’est plus valide.",
    pleaseTry: "Veuillez essayer une offre différente.",
    report: "Rapport",
    rewardsMessage: {
      one: "Vos remises seront ",
      two: "automatiquement",
      three: " ajouté à votre compte.",
    },
  },
};
