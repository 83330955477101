export const en = {
  ambassador: "Ambassador",
  applyFilters: "Apply filters",
  backToMain: "Back to main page",
  blog: "Blog",
  categories: "Categories",
  changePassword: "Change password",
  checkOut: "Check out",
  contact: "Contact",
  details: "Details",
  download: "Download the App!",
  earn: "Earn up to ",
  exclusive: "View exclusive offer",
  faq: "Frequently Asked Questions",
  featured: "Featured offers",
  filters: "Filters",
  firstName: "First name",
  gotIt: "Got it",
  orderBy: "Order by",
  emailAddress: "Email address",
  joinToEarn: "Join to earn",
  joinPartner: "Join as Partner",
  keepExploring: "Keep exploring",
  lastName: "Last name",
  listView: "List view",
  loading: "Loading...",
  login: "Log in",
  logOut: "Log out",
  newPassword: "New Password",
  noThanks: "No thanks",
  ok: "OK",
  oldPassword: "Old Password",
  or: "OR",
  password: "Password",
  passwordConfirmation: "Password Confirmation",
  privacyPolicy: "Privacy Policy",
  profile: "Profile",
  resetPassword: "Reset Password",
  search: "Search",
  select: "Select",
  selectCategory: "Select a category",
  selectCountry: "Select a country",
  selectLanguage: "Select a language",
  selectLocale: "Choose your Country / Language",
  send: "Send",
  shop: "Shop",
  shopAt: "Shop at",
  shopOnline: "Shop online",
  signIn: "Log in",
  signInToEarn: "Log in to earn",
  signInToView: "Log in to view offer",
  signUp: "Sign up",
  submit: "Submit",
  termsAndConditions: "Terms and Conditions",
  termsOfService: "Terms of Service",
  transaction: "Transaction",
  transactions: "Transactions",
  viewDeal: "View deal",
  wallet: "Wallet",
  welcome: "Welcome",
  whatILike: "What I Like",
  yourBalance: "Your balance",
  errors: {
    emailAddress: "Email address is not valid",
    emailAddressTaken: "This email address is already taken",
    firstName: "First name is required",
    invalidCredentials: "Invalid username or password",
    lastName: "Last name is required",
    password: "Password is not valid",
    passwordConfirmation: "Password confirmation does not match",
    passwordRequirements: "Must contain one upper case letter and number",
    fileUploadError:
      "An error occurred while attempting to upload your profile picture.",
    mongoError: "Something went wrong while signing you in. Please try again.",
  },
};
