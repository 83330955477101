import React from "react";

// Component imports
import {CircularProgress} from "@material";

// Misc Imports
import {Theme} from "@app-types";
import {makeStyles} from "@styles";

const useStyles = (props) =>
  makeStyles((theme: Theme) => ({
    progress: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: props.size,
    },
    spinner: {
      color:
        props.color === "white"
          ? theme.palette.secondary.main
          : theme.palette.primary.main,
    },
  }));

type Props = {
  size?: number;
  color?: "white" | "pink";
  style?: any;
};

export const LoadingSpinner = (props: Props) => {
  const classes: any = useStyles(props)({});

  return (
    <div className={classes.progress}>
      <CircularProgress
        size={props.size}
        style={props.style}
        className={classes.spinner}
      />
    </div>
  );
};
