import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  overrides: {
    MuiInput: {
      input: {
        "&::placeholder": {
          color: "#0C0121 !important",
          fontSize: 16,
          opacity: "1 !important",
        },
        color: "#0C0121",
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.8)",
      },
    },
    MuiModal: {
      root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "scroll",
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  shadows: Array(25).fill("none"),
  typography: {
    useNextVariants: true,
    fontFamily: "'Roboto', sans-serif",
  },
  layout: { spacing: 8 },
  palette: {
    black: "#000000",
    white: "#FFFFFF",
    primary: { main: "#FF3451" },
    secondary: { main: "#FFFFFF" },
    background: { default: "#FFFFFF" },
    colors: {
      bitcoin: "#F7931A",
      facebook: "#007DEC",
      profile: {
        background: "#582DFF",
      },
      background: {
        default: "#FFFFFF",
        lightGrey: "#F4F4F4",
        progressBar: "#E9E9E9",
      },
      border: {
        light: "#efefef",
        divider: "#DEDEDE",
      },
      button: {
        blackHover: "#696969",
        whiteHover: "#EFEFEF",
        disabled: "#464646",
        disabledText: "#EFEFEF",
      },
      text: {
        subheader: "#888888",
        placeholder: "#464646",
      },
      adBlockBanner: "#feebab",
    },
  },
} as any);

export { theme as GatsbyTheme };
