import React from "react";
import { Auth } from "aws-amplify";

// Component imports
import { DialogButton } from "@components";
import { Paper, Typography } from "@material";

// Misc Imports
import { Theme } from "@app-types";
import { Assets } from "@assets";
import { useTranslation } from "@i18n";
import { makeStyles } from "@styles";
import { ModalHelper, navigate } from "@utils";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: "auto",
    height: "auto",
    padding: 32,
    outline: "none !important",
    [theme.breakpoints.down("xs")]: {
      margin: "auto 16px auto 16px",
    },
  },
  insideContainer: {
    height: "auto",
    width: 300,
    borderRadius: 8,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      width: "90%",
    },
  },
  titleText: {
    fontSize: 24,
    fontWeight: 600,
    textAlign: "center",
    marginTop: 16,
    marginLeft: "auto",
    marginRight: "auto",
  },
  successText: {
    fontSize: 18,
    textAlign: "center",
    marginTop: 16,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 16,
  },
  astronaut: {
    width: 280,
    [theme.breakpoints.down("xs")]: {
      width: 265,
      height: 199,
    },
  },
}));

export function PasswordChangedSuccessModal() {
  const classes: any = useStyles({});
  const { t } = useTranslation(["profile", "common"]);

  const handleRedirectToSignIn = () => {
    ModalHelper.close();
    Auth.signOut();
    navigate("/sign-in");
  };

  return (
    <Paper className={classes.paper}>
      <div className={classes.insideContainer}>
        <img
          className={classes.astronaut}
          src={Assets.SPACE_DISCOVERY_ICON}
          alt="space-discovery-icon"
        />
        <Typography className={classes.titleText}>
          {t("modals.changePasswordSuccess.passwordChanged")}
        </Typography>
        <Typography className={classes.successText}>
          {t("modals.changePasswordSuccess.successText.one")}
        </Typography>
        <DialogButton
          colorVariant="black"
          width="100%"
          onClick={handleRedirectToSignIn}
        >
          {t("modals.changePasswordSuccess.successText.two")}
        </DialogButton>
      </div>
    </Paper>
  );
}
