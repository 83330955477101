import React from "react";

// Misc Imports
import {Theme} from "@app-types";
import {makeStyles} from "@styles";

const useStyles = (props: Props) =>
  makeStyles((theme: Theme) => ({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: props.width,
      height: props.height || 48,
      padding: 0,
      paddingRight: props.padding || 0,
      paddingLeft: props.padding || 0,
      borderRadius: props.borderRadius || 8,
      fontSize: props.fontSize || 18,
      fontWeight: props.fontWeight || 500,
      fontFamily: "'Roboto', sans-serif",
      textTransform: "none",
      outline: "none",
      cursor: props.disabled ? "default" : "pointer",
      backgroundColor: props.disabled
        ? theme.palette.colors.button.disabled
        : props.colorVariant === "white"
        ? theme.palette.white
        : props.colorVariant === "pink"
        ? theme.palette.primary.main
        : theme.palette.black,
      color: props.disabled
        ? theme.palette.colors.button.disabledText
        : props.colorVariant === "white"
        ? theme.palette.black
        : theme.palette.white,
      border: props.borderColor
        ? props.borderColor
        : props.colorVariant === "white"
        ? `1px solid ${theme.palette.black}`
        : "none",
      transitionProperty: "color, background-color, border",
      transitionDuration: "0.5s",
      transitionTimingFunction: "ease-out",
    },
    rootHover: {
      "&:hover": {
        backgroundColor:
          props.colorVariant === "black"
            ? theme.palette.colors.blackHover
            : props.colorVariant === "white"
            ? theme.palette.colors.whiteHover
            : theme.palette.black,
        color:
          props.colorVariant === "white"
            ? theme.palette.black
            : theme.palette.white,
        border:
          props.colorVariant === "white"
            ? `1px solid ${theme.palette.black}`
            : "none",
        transitionProperty: "color, background-color, border",
        transitionDuration: "0.4s",
      },
    },
  }));

type Props = {
  colorVariant: "black" | "white" | "pink";
  width: number | string;
  height?: number | string;
  onClick?: any;
  borderRadius?: number | string;
  fontSize?: number;
  fontWeight?: number;
  className?: string;
  style?: object;
  type?: "reset" | "button" | "submit";
  icon?: JSX.Element;
  padding?: number;
  borderColor?: string;
  hoverFade?: boolean;
  disabled?: boolean;
};

export const DialogButton: React.FC<Props> = (props) => {
  const {
    children,
    onClick,
    className,
    style,
    type,
    icon,
    hoverFade = true,
    disabled = false,
  } = props;
  const classes: any = useStyles(props)({});

  return (
    <button
      className={`${classes.root} ${className && className} ${
        hoverFade && classes.rootHover
      }`}
      onClick={disabled ? undefined : onClick}
      style={style}
      type={type}
    >
      {icon ? icon : null}
      {children}
    </button>
  );
};
