import React, { useCallback } from "react";

// Misc Imports
import { FilterData, RedirectData, StaticOffer, TermsData } from "@app-types";
import {
  createRedirectData,
  filterUserOffers,
  ModalHelper,
  openOfferRedirect,
} from "@utils";

// Component imports
import { WhatILikeCardView } from "@components";

export function WhatILikeCardsContainer({ offers, category, order, userId }) {
  const filterOptions: FilterData = {
    category,
    order,
  };
  const filteredUserOffers: StaticOffer[] = filterUserOffers(
    offers,
    filterOptions,
  );

  const onRedirectClicked = useCallback((offer: StaticOffer): void => {
    const redirectData: RedirectData = createRedirectData(offer, userId);
    openOfferRedirect(redirectData);
  }, []);

  const onTermsModalClicked = useCallback((offer: StaticOffer): void => {
    const termsModalProps: TermsData = {
      name: offer.advertiserName,
      terms: offer.terms,
      redirectData: createRedirectData(offer, userId),
      openOfferRedirect,
    };
    ModalHelper.open({ modalType: "terms", modalProps: termsModalProps });
  }, []);

  const whatILikeCards: JSX.Element[] = filteredUserOffers.map(
    (offer: StaticOffer) => (
      <WhatILikeCardView
        key={offer.id}
        offer={offer}
        onRedirectClicked={onRedirectClicked}
        onTermsModalClicked={onTermsModalClicked}
      />
    ),
  );

  return <>{whatILikeCards}</>;
}
