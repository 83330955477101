export const fr = {
  dialogText: {
    one:
      "La sécurité est l’une de nos priorités, c’est pourquoi nous avons limité la fonctionnalité de transfert à l’application mobile de Coinmiles.",
    two:
      "Téléchargez là dès maintenant pour transférer vos Bitcoin à votre portefeuille préféré ou à votre compte bancaire via Interac!   ",
    three:
      "Téléchargez là dès maintenant pour transférer vos Bitcoin à votre portefeuille préféré!   ",
  },
  myBalance: "Ma balance",
  btcPrice: "Prix du BTC",
  interacTransfer: "Transfert Interac",
  bitcoinSent: "Bitcoin envoyé",
  myActivity: "Mon activité",
  missing: "Transactions manquantes?",
  clickHere: "Cliquez ici",
  noRewards: "Pas encore de récompenses?",
  startVisiting: "Commencez à visiter nos partenaires et gagnez de l'argent.",
  welcomeBonus: "Cadeau de bienvenue",
  bonus: "Cadeau",
  referralSignUp: "s'est inscrite!",
  missingTransactionsModal: {
    whyTransactionMissing:
      "Pourquoi est-ce que ma transaction ne s’affiche pas? ",
    missingTransactionText: {
      one:
        " Ne vous inquiétez pas, votre transaction peut prendre jusqu'à 7 jours pour apparaître. Bien que nous fassions de notre mieux pour vous fournir l’information en temps réel au fur et à mesure que nous la recevons, nous travaillons avec divers partenaires qui n'ont pas tous implémenté cette capacité en temps réel. \n \n Dans certains cas, par exemple les agences de voyages en ligne, vos transactions ne peuvent apparaître qu’une fois que votre check out a été effectué. \n \n Si une transaction n’apparaît toujours pas après ce délai, contacter nous par courriel en ",
      two: "cliquant ici ",
      three:
        "et assurez-vous d’inclure une copie de votre reçu de transaction.",
    },
    transactionOtherCurrency:
      "Pourquoi est-ce que certaines transactions sont rapportées dans une devise étrangère?",
    transactionCurrencyAnswer:
      "Nos partenaires sont basés dans plusieurs pays. Certains d’entre eux convertissent automatiquement le montant de votre achat dans leur devise locale avant de nous transmettre l’information sur votre achat. La devise affichée est celle que nous recevons de nos partenaires.",
  },
};
