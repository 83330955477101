import { gql } from "@apollo/client";

export const USER_POOL_GET_USER_DATA = gql`
  query GetUserData($id: ID!) {
    user: getUser(id: $id) {
      id
      emailAddress
      firstName
      lastName
      didLogin
      country
      language
      profilePhotoUrl
    }
  }
`;
