import {Offer, OffersAmount} from "@models";

export const createPayoutText = (offer: Offer): string => {
  const unscaledPayoutValue = OffersAmount.getUnscaledValue(offer.payoutValue);
  const unscaledDivisor = OffersAmount.getUnscaledValue(offer.divisor);

  let payoutText = "";
  const displayedValue: number = Number(
    unscaledPayoutValue * (unscaledDivisor / 100)
  );

  if (offer.payoutValue) {
    payoutText =
      offer.payoutType === "PAYOUT_BY_PERCENT"
        ? `${parseFloat(displayedValue.toFixed(1))}%`
        : `$${displayedValue.toFixed(2)}`;
  }

  return payoutText;
};
