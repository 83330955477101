import React, { useState, useEffect, MouseEvent } from "react";
import { motion } from "framer-motion";

import {
  currentUserVar,
  useMutation,
  useReactiveVar,
  UserPoolClient,
} from "@apollo";
import {
  MUTATION_DELETE_USER_OFFER,
  USER_POOL_GET_USER_OFFERS,
} from "@queries";

// Misc Imports
import { StaticOffer } from "@app-types";
import { useMediaQuery } from "@hooks";
import { useTranslation } from "@i18n";
import { Offer } from "@models";
import { makeStyles, cardVariants, arrowBounce } from "@styles";
import { Theme } from "@app-types";

// Component imports
import { Image, PayoutBanner } from "@components";
import { HeartIconFilled, RightArrowIcon } from "@icons";
import { Grid, Typography } from "@material";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 148,
    width: "100%",
    border: "1px solid #CDCDCD",
    borderRadius: 8,
    marginBottom: 16,
    cursor: "pointer",
    bottom: 0,
    transitionProperty: "transform, box-shadow, bottom",
    transitionDuration: "0.3s",
    transitionTimingFunction: "ease-out",
    "&:hover": {
      bottom: 5,
      boxShadow: "0 4px 10px 0 rgba(209,209,209,0.5)",
      transitionProperty: "transform, box-shadow, bottom",
      transitionDuration: "0.3s",
      cursor: "pointer",
    },
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      minHeight: 96,
    },
  },
  brandLogoContainer: {
    position: "relative",
    minWidth: 156,
    height: 109,
    marginLeft: 20,
    maxWidth: 64,
    marginTop: 8,
    marginBottom: 8,
    border: "1px solid #CDCDCD",
    borderRadius: 4,
    [theme.breakpoints.down("xs")]: {
      border: "none",
      minWidth: 64,
      height: 64,
      marginLeft: 8,
    },
  },
  cardPressed: {
    transform: "scale(0.97) !important",
    bottom: 0,
    transitionProperty: "transform, box-shadow, bottom",
    transitionDuration: "0.3s",
  },
  brandLogo: {
    margin: "auto",
    height: 90,
    width: 90,
    [theme.breakpoints.down("xs")]: {
      height: 64,
      width: 64,
    },
  },
  advertiserNameContainer: {
    display: "flex",
    alignSelf: "flex-start",
    justifyContent: "space-between",
    height: 106,
    marginTop: 8,
    marginLeft: 32,
    [theme.breakpoints.down("xs")]: {
      marginLeft: "3vw",
      height: "auto",
    },
  },
  advertiserName: {
    fontSize: 28,
    fontWeight: 700,
    color: "#000000",
    marginTop: 12,
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
    [theme.breakpoints.down(376)]: {
      fontSize: 15,
    },
    [theme.breakpoints.down(361)]: {
      fontSize: 13,
    },
  },
  payoutContainer: {
    [theme.breakpoints.up("md")]: {
      marginBottom: 8,
    },
  },
  termsButton: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    bottom: 4,
    textAlign: "center",
    padding: 0,
    width: 72,
    height: 31,
    fontSize: 18,
    fontWeight: 425,
    textTransform: "none",
    marginTop: 10,
    marginRight: 20,
    top: 3,
    "&:hover": {
      backgroundColor: "white !important",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      left: 10,
    },
  },
  heartDetailsContainer: {
    height: 109,
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  heartContainer: {
    height: 26,
    width: 26,
    display: "flex",
    alignSelf: "flex-end",
    marginTop: 8,
    marginRight: 20,
    cursor: "pointer",
    transitionProperty: "transform",
    transitionDuration: "0.3s",
    transitionTimingFunction: "ease-out",
    "&:hover": {
      transform: "scale(1.15)",
      transitionProperty: "transform",
      transitionDuration: "0.3s",
    },
    [theme.breakpoints.down("sm")]: {
      height: 20,
      width: 20,
    },
  },
  heartPressed: {
    transform: "scale(0.93) !important",
    bottom: 0,
    transitionProperty: "transform",
    transitionDuration: "0.3s",
  },
  arrow: {
    width: 16,
    position: "relative",
    top: 2,
    marginLeft: 4,
    [theme.breakpoints.down("md")]: {
      width: 12,
      top: 2,
    },
    [theme.breakpoints.down("xs")]: {
      width: 10,
      top: 2,
    },
  },
}));

type Props = {
  offer: StaticOffer;
  onRedirectClicked: (offer: StaticOffer) => void;
  onTermsModalClicked: (offer: StaticOffer) => void;
};

export function WhatILikeCardView({
  offer,
  onRedirectClicked,
  onTermsModalClicked,
}: Props) {
  const classes: any = useStyles({});
  const smallPhone: boolean = useMediaQuery("(max-width:359px)");
  const small: boolean = useMediaQuery("(max-width:959px)");
  const { t } = useTranslation("common");

  const user = useReactiveVar(currentUserVar);

  useEffect(() => {
    small ? setTermsVisible(true) : setTermsVisible(false);
  }, [small]);

  const [cardAnimation, setCardAnimation] = useState<boolean>(false);
  const [heartAnimation, setHeartAnimation] = useState<boolean>(false);
  const [termsVisible, setTermsVisible] = useState<boolean>(false);

  const handleCardClick = (event: MouseEvent<HTMLInputElement>): void => {
    if (event.button === 0) {
      setCardAnimation(true);
      setTimeout(() => {
        setCardAnimation(false);
        onRedirectClicked(offer);
      }, 400);
    }
  };

  const handleHeartClick = (event: MouseEvent<HTMLInputElement>): void => {
    if (event.button === 0) {
      event.stopPropagation();
      setHeartAnimation(true);

      setTimeout(() => {
        setHeartAnimation(false);
        deleteUserOffer();
      }, 400);
    }
  };

  const handleMouseHover = (): void => {
    setTermsVisible(!termsVisible);
  };

  const handleTermsButtonClick = (
    event: MouseEvent<HTMLInputElement>,
  ): void => {
    if (event.button === 0) {
      event.stopPropagation();
      onTermsModalClicked(offer);
    }
  };

  const [deleteUserOffer] = useMutation(MUTATION_DELETE_USER_OFFER, {
    client: UserPoolClient,
    variables: { userId: user.id, offerId: offer.id },
    optimisticResponse: {
      deleteUserOffer: offer.id,
    },
    update(cache, { data: { deleteUserOffer } }) {
      const { userOffers } = cache.readQuery<{ userOffers: Offer[] }>({
        query: USER_POOL_GET_USER_OFFERS,
        variables: { id: user.id },
      });

      cache.writeQuery({
        query: USER_POOL_GET_USER_OFFERS,
        variables: { id: user.id },
        data: {
          userOffers: userOffers.filter(
            (userOffer) => userOffer.id !== deleteUserOffer,
          ),
        },
      });
    },
    onError: (err) => console.error(`Error deleting user offer: ${err}`),
  });

  return (
    <Grid
      container
      item
      direction="row"
      justify="space-between"
      alignItems="center"
      xs={12}
      className={`${classes.root} ${cardAnimation && classes.cardPressed}`}
      onMouseDown={handleCardClick}
      onMouseEnter={!termsVisible && !small ? handleMouseHover : undefined}
      onMouseLeave={termsVisible && !small ? handleMouseHover : undefined}
    >
      <Grid
        container
        item
        direction="row"
        justify="flex-start"
        alignItems="center"
        xs={8}
        lg={8}
      >
        <Grid
          container
          item
          justify="center"
          alignItems="center"
          className={classes.brandLogoContainer}
        >
          <Image
            image={offer.advertiserLogoImage}
            className={classes.brandLogo}
            alt={`${offer.id}-advertiser-logo`}
          />
        </Grid>
        <Grid
          container
          item
          direction="column"
          justify="space-between"
          alignItems="flex-start"
          xs
          className={classes.advertiserNameContainer}
        >
          <Grid
            container
            item
            direction="row"
            justify="flex-start"
            alignItems="center"
            style={{ position: "relative", top: "-6px" }}
          >
            <Typography className={classes.advertiserName}>
              {offer.advertiserName}
            </Typography>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justify="flex-start"
            alignItems="center"
            className={classes.payoutContainer}
          >
            <PayoutBanner
              payoutText={offer.payoutText}
              smallPhone={smallPhone}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction="column"
        justify="space-between"
        alignItems="flex-end"
        xs={4}
        lg={2}
        className={classes.heartDetailsContainer}
      >
        <Grid
          className={`${classes.heartContainer} ${
            heartAnimation && classes.heartPressed
          }`}
          onMouseDown={handleHeartClick}
        >
          {" "}
          <HeartIconFilled fill="black" width={"100%"} />
        </Grid>
        <Grid>
          {termsVisible && (
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={cardVariants}
              whileHover="hover"
            >
              <div
                onMouseDown={handleTermsButtonClick}
                className={classes.termsButton}
              >
                {t("details")}
                <motion.div variants={arrowBounce} className={classes.arrow}>
                  <RightArrowIcon width={"100%"} fill={"black"} />
                </motion.div>
              </div>
            </motion.div>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
