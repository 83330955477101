export const en = {
  shopOnline: "Shop Online",
  localOffers: "Local Offers",
  myWallet: "My Wallet",
  whatILike: "What I Like",
  login: "Log in",
  loginToEarn: "Log in to earn",
  join: "Join to earn",
  adblockDetected: {
    bold: "It appears you have AdBlock enabled! ",
    message:
      "In order for Coinmiles to be able to reward you you must disable your AdBlocker.",
  },
};
