import React from "react";

import {
  currentCountryVar,
  currentUserVar,
  useQuery,
  useReactiveVar,
} from "@apollo";
import { SCREEN_GET_NAVBAR_DATA } from "@queries";

// Misc Imports
import { ExchangeRate, Theme } from "@app-types";
import { useDetectAdBlock } from "@hooks";
import { Wallet } from "@models";
import { makeStyles } from "@styles";

// Component imports
import { CoinmilesLogo, Link } from "@components";
import { AppBar, Grid, Hidden, Toolbar } from "@material";

import { AdblockBanner } from "./ad-block-banner";
import { NavbarButtons } from "./navbar-buttons";
import { NavbarLocaleSelectionContainer } from "./navbar-locale-selection-container";
import { NavbarLoginButton } from "./navbar-login-button";
import { NavbarMenuLarge } from "./navbar-menu-large";
import { NavbarMenuMobile } from "./navbar-menu-mobile";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.white,
    zIndex: 10,
    paddingBottom: 11,
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
      marginBottom: 3,
    },
  },
  toolbar: {
    maxWidth: 1400,
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: "0px",
    paddingRight: "0px",
    width: "80%",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    height: 25.8,
    width: 115,
    marginLeft: 0,
    marginTop: 0,
    marginRight: 63,
    [theme.breakpoints.down("md")]: {
      marginRight: 32,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 5,
      marginRight: 0,
    },
  },
}));

type NavbarData = {
  wallet: Wallet;
  usdRate: ExchangeRate;
};

export function Navbar({ userId }) {
  const classes: any = useStyles({});

  const country = useReactiveVar(currentCountryVar);
  const user = useReactiveVar(currentUserVar);

  const adBlockDetected = useDetectAdBlock();

  const { data, loading } = useQuery<NavbarData>(SCREEN_GET_NAVBAR_DATA, {
    variables: { id: user?.id, currency: country?.currencyCode },
  });

  return (
    <AppBar position="sticky" className={classes.root}>
      {adBlockDetected && <AdblockBanner />}
      <Toolbar className={classes.toolbar}>
        <Grid container justify="space-between">
          <Grid
            container
            item
            alignItems="center"
            xs={4}
            md={8}
            className={classes.leftSide}
          >
            <Link to="/">
              <CoinmilesLogo className={classes.logo} />
            </Link>
            <Hidden only={["xs", "sm"]}>
              <NavbarButtons userId={userId} />
            </Hidden>
          </Grid>
          <Grid
            container
            item
            justify="flex-end"
            alignItems="center"
            xs={8}
            md={4}
            className={classes.rightside}
          >
            <NavbarLocaleSelectionContainer />
            <Hidden only={["sm", "md", "lg", "xl"]}>
              <Grid item>
                {!!user ? (
                  <NavbarMenuMobile user={user} loading={loading} />
                ) : (
                  <NavbarLoginButton small />
                )}
              </Grid>
            </Hidden>
            <Hidden only={["xs"]}>
              {!!user ? (
                <NavbarMenuLarge
                  user={user}
                  wallet={data?.wallet}
                  country={country}
                  loading={loading}
                />
              ) : (
                <NavbarLoginButton />
              )}
            </Hidden>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
