import {
  currentCountryVar,
  currentUserVar,
  initCache,
  InMemoryCache,
  UserPoolClient,
} from "@apollo";
import { USER_POOL_GET_WALLET_DATA } from "@queries";

import { AffiliateServiceArea } from "@app-types";
import { User, Wallet } from "@models";
import {
  getAffiliateServiceArea,
  isServerSideRendering,
  LocaleUtils,
} from "@utils";

// Checks that the found object contains the minimum required fields for a user
const checkThatIsUserObject = (object: any): boolean =>
  !!object.id &&
  !!object.firstName &&
  !!object.lastName &&
  !!object.emailAddress;

/*
This function is called on every startup of the app and is responsible for:
  - Initializing the Apollo cache persistor (using localStorage)
  - Assigning the current AffiliateServiceArea to the currentCountry Apollo reactive var
  - Ensuring a language value is set in localStorage (defaults to "en" if not set)
  - If a user was previously signed in, assigning their data to the currentUser Apollo reactive var 
*/
export const initializeAppStartup = async (): Promise<void> => {
  if (isServerSideRendering()) return;

  initCache();
  // Updates wallet if page has just been refreshed due to a user changing country and language at the same time
  const userCountrySavedBeforeRefresh = localStorage.getItem(
    "userCountrySavedBeforeRefresh",
  );

  const cache: string = localStorage.getItem("coinmiles-cache-persist");
  if (cache) {
    const parsedCache: InMemoryCache = JSON.parse(cache);
    const cachedUser: User = Object.keys(parsedCache)
      .filter((cacheKeys: string) => cacheKeys.indexOf("User:") != -1)
      .map((indexOfUser: string) => parsedCache[indexOfUser])[0];

    if (cachedUser && checkThatIsUserObject(cachedUser)) {
      currentUserVar(cachedUser);

      if (!!userCountrySavedBeforeRefresh) {
        const newCountry: AffiliateServiceArea = getAffiliateServiceArea(
          userCountrySavedBeforeRefresh,
        );

        await UserPoolClient.query<{ wallet: Wallet }>({
          query: USER_POOL_GET_WALLET_DATA,
          variables: { id: cachedUser?.id, currency: newCountry?.currencyCode },
          fetchPolicy: "network-only",
        });

        localStorage.setItem("userCountry", newCountry.countryCode);
        currentCountryVar(newCountry);

        localStorage.removeItem("userCountrySavedBeforeRefresh");
      } else {
        await LocaleUtils.setStartupCountry();
        LocaleUtils.setStartupLanguage();
      }
    }
  } else {
    if (!!userCountrySavedBeforeRefresh) {
      const newCountry: AffiliateServiceArea = getAffiliateServiceArea(
        userCountrySavedBeforeRefresh,
      );

      localStorage.setItem("userCountry", newCountry.countryCode);
      currentCountryVar(newCountry);

      localStorage.removeItem("userCountrySavedBeforeRefresh");
    } else {
      await LocaleUtils.setStartupCountry();
      LocaleUtils.setStartupLanguage();
    }
  }
};
