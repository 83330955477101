import React from "react";
import { Auth } from "aws-amplify";

// Component imports
import { DialogButton } from "@components";
import { Grid, Paper, Typography } from "@material";

// Misc Imports
import { Theme } from "@app-types";
import { Assets } from "@assets";
import { useTranslation } from "@i18n";
import { makeStyles } from "@styles";
import { ModalHelper } from "@utils";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    outline: "none",
    padding: 32,
    [theme.breakpoints.down("xs")]: {
      width: 320,
      padding: 16,
      height: "auto",
      margin: "auto 16px auto 16px",
    },
  },
  dialog: {
    height: 400,
    width: 480,
    borderRadius: 8,
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      width: "100%",
    },
  },
  mailIcon: { marginTop: 8 },
  signInText: {
    fontSize: 30,
    fontWeight: 600,
    textAlign: "center",
    marginTop: 22,
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  dialogText: {
    fontSize: 20,
    fontWeight: 400,
    textAlign: "center",
    marginTop: 11,
    marginBottom: 26,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  menuButton: {
    marginBottom: 8,
    width: 284,
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
}));

export function ActivationModal({ emailAddress }) {
  const classes: any = useStyles({});
  const { t } = useTranslation("signIn");

  const handleResend = async (): Promise<void> => {
    try {
      await Auth.resendSignUp(emailAddress);
      ModalHelper.close();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="column"
        className={classes.dialog}
      >
        <Grid item className={classes.mailIcon}>
          <img
            style={{ height: "100%", width: "100%" }}
            src={Assets.MAIL_ICON}
            alt="mail-icon"
          />
        </Grid>
        <Grid container item justify="center">
          <Typography className={classes.signInText}>
            {t("activationModal.confirmEmail")}
          </Typography>
        </Grid>
        <Grid container item justify="center">
          <Typography className={classes.dialogText}>
            {t("activationModal.sentEmail")}
            <br />
            {t("activationModal.confirmBefore")}
          </Typography>
        </Grid>
        <Grid
          container
          item
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid className={classes.menuButton}>
            <DialogButton
              width={"100%"}
              colorVariant="black"
              onClick={handleResend}
            >
              {t("activationModal.sendAgain")}
            </DialogButton>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
