export const en = {
  resetPassword: "Reset Password",
  enterTheCode: "Enter the code that was sent to the email address provided.",
  code: "Code",
  passwordConf: "New Password Confirmation",
  errorText: {
    oops: "Oops. Looks like that link is expired.",
    sendNew: "Click here to send a new link.",
    invalidCode: "Invalid code",
  },
};
