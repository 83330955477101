import React, { useCallback, ChangeEvent } from "react";

// Component imports
import {
  CategoryDropdown,
  DisplayToggle,
  OrderDropdown,
  SearchField,
} from "@components";
import { Grid, Hidden } from "@material";

// Misc Imports
import { OnlineScreenFilters, Theme } from "@app-types";
import { makeStyles } from "@styles";

const useStyles = makeStyles((theme: Theme) => ({
  stickyHeader: {
    position: "sticky",
    top: 83,
    backgroundColor: theme.palette.white,
    zIndex: 4,
    paddingBottom: 16,
    marginTop: 15,
    [theme.breakpoints.down("lg")]: {
      paddingBottom: 11,
      top: 83,
      marginTop: 15,
    },
    [theme.breakpoints.down("md")]: {
      top: 74,
      marginTop: 0,
    },
    [theme.breakpoints.down("xs")]: {
      top: 67,
    },
  },
  mobileToggleContainer: {
    marginLeft: 8,
  },
  selectorsHeaderMedium: {
    paddingTop: 9,
  },
  searchBar: {
    width: 338,
    [theme.breakpoints.up(1100)]: {
      width: 438,
    },
  },
  categoriesHeader: {
    backgroundColor: theme.palette.white,
    zIndex: 5,
    paddingTop: 2,
  },
}));

type Props = {
  filters: OnlineScreenFilters;
  setFilters: any;
};

export const FiltersHeader = ({ filters, setFilters }: Props) => {
  const classes: any = useStyles({});

  const handleSearchInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target as HTMLInputElement;
      setFilters((prevState) => ({
        ...prevState,
        searchString: value,
      }));
    },
    [],
  );

  return (
    <>
      {/* Mobile View */}
      <Hidden only={["sm", "md", "lg", "xl"]}>
        <Grid
          container
          item
          justify="space-between"
          alignItems="center"
          xs={12}
          className={classes.stickyHeader}
        >
          <Grid item xs>
            <SearchField
              width={"100%"}
              onChange={(event) => handleSearchInput(event)}
            />
          </Grid>
          <Grid className={classes.mobileToggleContainer}>
            <DisplayToggle
              type="mobile"
              display={filters.display}
              setFilters={setFilters}
            />
          </Grid>
        </Grid>
        <div className={classes.categoriesHeader}>
          <Grid container item justify="space-between" xs={12} sm>
            <Grid item xs={6} sm={6}>
              <CategoryDropdown
                category={filters.category}
                setFilters={setFilters}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <OrderDropdown order={filters.order} setFilters={setFilters} />
            </Grid>
          </Grid>
        </div>
      </Hidden>
      {/* Tablet View */}
      <Hidden only={["xs", "md", "lg", "xl"]}>
        <Grid className={classes.stickyHeader}>
          <SearchField
            width={"100%"}
            onChange={(event) => handleSearchInput(event)}
          />
        </Grid>
        <Grid
          container
          item
          justify="flex-end"
          alignItems="flex-start"
          className={classes.selectorsHeaderMedium}
        >
          <CategoryDropdown
            category={filters.category}
            setFilters={setFilters}
          />
          <OrderDropdown order={filters.order} setFilters={setFilters} />
          <DisplayToggle
            type="desktop"
            display={filters.display}
            setFilters={setFilters}
          />
        </Grid>
      </Hidden>
      {/* Desktop View */}
      <Hidden only={["xs", "sm"]}>
        <Grid
          container
          justify="space-between"
          className={classes.stickyHeader}
        >
          <Grid className={classes.searchBar}>
            <SearchField
              width={"100%"}
              onChange={(event) => handleSearchInput(event)}
            />
          </Grid>
          <Grid container item justify="flex-end" alignItems="flex-start" md>
            <CategoryDropdown
              category={filters.category}
              setFilters={setFilters}
            />
            <OrderDropdown order={filters.order} setFilters={setFilters} />
            <DisplayToggle
              type="desktop"
              display={filters.display}
              setFilters={setFilters}
            />
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
};
