import { gql } from "@apollo/client";

export const USER_POOL_GET_WALLET_DATA = gql`
  query UserPoolGetWalletData($id: ID!, $currency: String) {
    wallet: getWallet(id: $id, currency: $currency) {
      userId
      balanceIncludingPendingInFiat {
        currency
        value
      }
      balanceNotIncludingFrozen {
        btc {
          value
        }
      }
      balanceIncludingFrozen {
        fiat {
          currency
          value
        }
      }
      transactions {
        id
        createdAt
        externalRef {
          type
          data {
            advertiserName
            amount {
              fiat {
                value
              }
            }
            campaign {
              account {
                images {
                  original
                }
                industry
                name
                useLogoImage
              }
            }
            externalData {
              offer {
                advertiserLogo
              }
            }
            logo
            referredUserFirstName
          }
        }
        type
        amount {
          fiat {
            currency
            value
          }
          btc {
            currency
            value
          }
        }
      }
    }
    usdRate: getExchangeRate(initialCurrency: "USD", convertedCurrency: "CAD") {
      exchangeRate
    }
  }
`;
