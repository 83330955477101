import {navigate as gatsbyNavigate} from "gatsby";

type Options = {
  state?: any;
  replace?: boolean;
};

// This abstraction only exists because Gatsby's navigate function was causing
// errors at build time. The window check fixed the issue.
export const navigate = (path: string, options?: Options): void => {
  if (typeof window === "undefined") return;

  gatsbyNavigate(path, options);
};
