import i18n, { Resource } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { translations as Common } from ".";
// Screen Translations
import { translations as ChangePassword } from "../components/screens/change-password/translations";
import { translations as Footer } from "../components/layout/footer/translations";
import { translations as Navbar } from "../components/layout/navbar/translations";
import { translations as OnlineScreen } from "../components/screens/online/translations";
import { translations as Profile } from "../components/screens/profile/translations";
import { translations as ResetPassword } from "../components/screens/reset-password/translations";
import { translations as SignIn } from "../components/screens/sign-in/translations";
import { translations as SignUp } from "../components/screens/sign-up/translations";
import { translations as Wallet } from "../components/screens/wallet/translations";
import { translations as WhatILike } from "../components/screens/what-i-like/translations";
// import {translations as LinkedCardDeleteDialog} from "../components/common/linked-card-delete-dialog/translations";
// import {translations as MissingTransactionsDialog} from "../components/common/missing-transactions-dialog/translations";
// import {translations as SuccessDialog} from "../containers/success-dialog/translations";
// Container Translations
// import {translations as ChangePasswordSuccess} from "../containers/change-password-success/translations";
// import {translations as ProfileLinkedCardsDisplay} from "../containers/profile-linked-cards-display/translations";
// import {translations as RedirectScreen} from "../containers/redirect-screen/translations";
// import {translations as RestorePassword} from "../containers/restore-password/translations";
// import {translations as RestorePasswordSuccess} from "../containers/restore-password-success/translations";
// import {translations as Success} from "../containers/success/translations";
// import {translations as UserActivation} from "../containers/user-activation/translations";
// import {translations as UserActivationSuccess} from "../containers/user-activation-success/translations";
// import {translations as WalletActivity} from "../containers/wallet-activity/translations";
// import {translations as WalletDisplay} from "../containers/wallet-display/translations";
// import {translations as WhatILikeFilters} from "../containers/user-offers-filters/translations";
// import {translations as UserOffersScreen} from "../containers/user-offers-screen/translations";
// import {translations as UserTutorialDrawer} from "../containers/user-tutorial-drawer/translations";
// import {translations as UserTutorialModal} from "../containers/user-tutorial-modal/translations";

const resources: Resource = {
  en: {
    ...ChangePassword.en,
    ...Common.en,
    ...Footer.en,
    ...Navbar.en,
    ...OnlineScreen.en,
    ...Profile.en,
    ...ResetPassword.en,
    ...SignIn.en,
    ...SignUp.en,
    ...Wallet.en,
    ...WhatILike.en,
    // ...LinkedCardDeleteDialog.en,
    // ...MissingTransactionsDialog.en,
    // ...SuccessDialog.en,
    // ...ChangePasswordSuccess.en,
    // ...ProfileLinkedCardsDisplay.en,
    // ...RedirectScreen.en,
    // ...RestorePassword.en,
    // ...RestorePasswordSuccess.en,
    // ...Success.en,
    // ...UserActivation.en,
    // ...UserActivationSuccess.en,
    // ...WalletActivity.en,
    // ...WalletDisplay.en,
    // ...WhatILikeFilters.en,
    // ...UserOffersScreen.en,
    // ...UserTutorialDrawer.en,
    // ...UserTutorialModal.en,
  },
  fr: {
    ...ChangePassword.fr,
    ...Common.fr,
    ...Footer.fr,
    ...Navbar.fr,
    ...OnlineScreen.fr,
    ...Profile.fr,
    ...ResetPassword.fr,
    ...SignIn.fr,
    ...SignUp.fr,
    ...Wallet.fr,
    ...WhatILike.fr,
    // ...LinkedCardDeleteDialog.fr,
    // ...MissingTransactionsDialog.fr,
    // ...SuccessDialog.fr,
    // ...ChangePasswordSuccess.fr,
    // ...ProfileLinkedCardsDisplay.fr,
    // ...RedirectScreen.fr,
    // ...RestorePassword.fr,
    // ...RestorePasswordSuccess.fr,
    // ...Success.fr,
    // ...UserActivation.fr,
    // ...UserActivationSuccess.fr,
    // ...WalletActivity.fr,
    // ...WalletDisplay.fr,
    // ...WhatILikeFilters.fr,
    // ...UserOffersScreen.fr,
    // ...UserTutorialDrawer.fr,
    // ...UserTutorialModal.fr,
  },
};

// Language detection options
const options: object = {
  order: ["localStorage"],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: options,
    fallbackLng: "en",
    debug: process.env.NODE_ENV === "production" ? false : true,

    interpolation: {
      escapeValue: false,
    },

    react: {
      wait: true,
    },
  });

export { i18n };
