import {OnlineScreenFilters, StaticOffer} from "@app-types";

export function filterOffers(
  offers: StaticOffer[],
  filterData: OnlineScreenFilters
): StaticOffer[] {
  const {category, order, searchString} = filterData;

  return offers
    .filter((offer: StaticOffer) => {
      if (searchString === "") {
        return offer;
      } else if (
        offer.advertiserName.toLowerCase().includes(searchString.toLowerCase())
      ) {
        return offer;
      } else {
        return null;
      }
    })
    .filter((offer: StaticOffer) => {
      if (category === "ALL") {
        return offer;
      } else if (offer.categories && offer.categories.includes(category)) {
        return offer;
      } else {
        return null;
      }
    })
    .sort((a: StaticOffer, b: StaticOffer) => {
      if (order === "Featured") {
        return (
          Number(b.displayOrder !== null) - Number(a.displayOrder !== null) ||
          Number(a.displayOrder) - Number(b.displayOrder)
        );
      } else if (order === "Highest cashback") {
        return a.payoutValue === b.payoutValue
          ? 0
          : a.payoutValue > b.payoutValue
          ? -1
          : 1;
      } else if (order === "A-Z") {
        return a.advertiserName.toLowerCase() === b.advertiserName.toLowerCase()
          ? 0
          : a.advertiserName.toLowerCase() < b.advertiserName.toLowerCase()
          ? -1
          : 1;
      } else if (order === "Z-A") {
        return a.advertiserName.toLowerCase() === b.advertiserName.toLowerCase()
          ? 0
          : a.advertiserName.toLowerCase() > b.advertiserName.toLowerCase()
          ? -1
          : 1;
      } else if (order === "New offers") {
        return a.activeDate === b.activeDate
          ? 0
          : a.activeDate > b.activeDate
          ? -1
          : 1;
      } else {
        return 0;
      }
    });
}

export function filterUserOffers(
  offers: StaticOffer[],
  filterData: any
): StaticOffer[] {
  const {category, order} = filterData;

  return offers
    .filter((offer: StaticOffer) => {
      if (category === "All Offers") {
        return offer;
      } else if (category === "Online") {
        return offer;
      } else {
        return null;
      }
    })
    .sort((a: StaticOffer, b: StaticOffer) => {
      if (order === "Featured") {
        return (
          Number(b.displayOrder !== null) - Number(a.displayOrder !== null) ||
          Number(a.displayOrder) - Number(b.displayOrder)
        );
      } else if (order === "Highest cashback") {
        return a.payoutValue === b.payoutValue
          ? 0
          : a.payoutValue > b.payoutValue
          ? -1
          : 1;
      } else if (order === "A-Z") {
        return a.advertiserName.toLowerCase() === b.advertiserName.toLowerCase()
          ? 0
          : a.advertiserName.toLowerCase() < b.advertiserName.toLowerCase()
          ? -1
          : 1;
      } else if (order === "Z-A") {
        return a.advertiserName.toLowerCase() === b.advertiserName.toLowerCase()
          ? 0
          : a.advertiserName.toLowerCase() > b.advertiserName.toLowerCase()
          ? -1
          : 1;
      } else if (order === "New offers") {
        return a.activeDate === b.activeDate
          ? 0
          : a.activeDate > b.activeDate
          ? -1
          : 1;
      } else {
        return 0;
      }
    });
}
