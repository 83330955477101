import React from "react";

// Component imports
import {InputAdornment, TextField} from "@material";

// Misc Imports
import {Theme} from "@app-types";
import {useTranslation} from "@i18n";
import {makeStyles} from "@styles";

const useStyles = makeStyles((theme: Theme) => ({
  signupField: {
    marginTop: 10,
    marginRight: "auto",
    marginLeft: 0,
    width: "100%",
  },
  outlinedInput: {
    height: 45,
    backgroundColor: theme.palette.white,
    borderRadius: 5,
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
    "&&&&:hover:before": {
      borderBottom: "none",
    },
  },
  input: {
    marginLeft: 10,
    fontSize: 14,
    "&::placeholder": {
      color: "black !important",
    },
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    outline: "none",
    fontFamily: "'Roboto', sans-serif",
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.white,
    marginRight: "-9px",
    borderRadius: "0px 5px 5px 0px",
    padding: "6px 8px 6px 8px",
    height: 45,
    width: 64,
    backgroundColor: theme.palette.primary.main,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export function EmailSignupField() {
  const classes: any = useStyles({});
  const {t} = useTranslation("common");

  return (
    <TextField
      id="email-signup"
      type="form"
      placeholder={t("emailAddress")}
      className={classes.signupField}
      InputProps={{
        classes: {
          root: classes.outlinedInput,
          input: classes.input,
        },
        endAdornment: (
          <InputAdornment position="start">
            <div type="submit" className={classes.button}>
              <span>{t("send")}</span>
            </div>
          </InputAdornment>
        ),
      }}
    />
  );
}
