import React from "react";

// Misc Imports
import { Theme } from "@app-types";
import { LinkedCard } from "@models";
import { makeStyles } from "@styles";
import { ModalHelper } from "@utils";

// Component imports
import { MastercardIcon, VisaIcon, TrashIcon } from "@icons";
import { Grid, Typography } from "@material";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    backgroundColor: theme.palette.white,
    width: "100%",
    height: 90,
    paddingTop: 15,
    paddingBottom: 15,
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  cardLogoContainer: {
    width: 62,
    height: 42,
    borderRadius: 4,
    marginRight: 16,
    [theme.breakpoints.down("xs")]: {
      marginRight: 10,
      width: 46,
      height: 31,
    },
  },
  cardLogoImage: {
    width: 38,
    zIndex: 5,
    [theme.breakpoints.down("xs")]: {
      width: 28,
    },
  },
  cardNumber: {
    color: theme.palette.black,
    fontSize: 16,
    fontWeight: 600,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  cardExpiry: {
    color: theme.palette.black,
    fontSize: 14,
    fontWeight: 500,
  },
  deleteIcon: {
    width: 42,
    height: 42,
    border: `1px solid ${theme.palette.colors.border.light}`,
    borderRadius: 4,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: `${theme.palette.colors.background.lightGrey} !important`,
      transitionProperty: "background-color",
      transitionDuration: "0.3s",
    },
  },
}));

type Props = { card: LinkedCard };

export function ProfileLinkedCard({ card }: Props) {
  const classes: any = useStyles({});

  const handleLinkedCardDeleteModalOpen = (): void => {
    ModalHelper.open({
      modalType: "linkedCardDelete",
      modalProps: {
        id: card.id,
        externalId: card.externalId,
      },
    });
  };

  const cardType: string = card.brand;

  const cardLogos = {
    mastercard: {
      icon: <MastercardIcon width={"100%"} className={classes.cardLogoImage} />,
      color: "#040E19",
    },
    visa: {
      icon: <VisaIcon width={"100%"} className={classes.cardLogoImage} />,
      color: "#1A1F71",
    },
  };

  return (
    <Grid
      container
      item
      direction="row"
      justify="space-between"
      alignItems="center"
      xs={12}
      className={classes.card}
    >
      <Grid
        container
        item
        direction="row"
        justify="flex-start"
        alignItems="center"
        xs={9}
      >
        <Grid
          container
          item
          justify="center"
          alignItems="center"
          className={classes.cardLogoContainer}
          style={{ backgroundColor: cardLogos[cardType].color }}
        >
          {cardLogos[cardType].icon}
        </Grid>
        <Grid container item direction="column" xs={8}>
          <Typography
            className={classes.cardNumber}
          >{`**** **** **** ${card.last4}`}</Typography>
          <Typography
            className={classes.cardExpiry}
          >{`${card.expMonth}/${card.expYear}`}</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        justify="center"
        alignItems="center"
        className={classes.deleteIcon}
        onClick={handleLinkedCardDeleteModalOpen}
      >
        <TrashIcon width={22} fill={"#000000"} />
      </Grid>
    </Grid>
  );
}
