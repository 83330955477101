export const en = {
  filtersHeader: {
    filters: "Filters",
    howItWorks: "How it works",
    more: "+ More",
    orderBy: "Order by:",
    categories: {
      ALL: "All Offers",
      MENS: "Men's",
      WOMENS: "Women's",
      SPORTS_OUTDOOR: "Sports & Outdoor",
      HOME_GARDEN: "Home & Garden",
      ELECTRONICS: "Electronics",
      HOTEL_TRAVEL: "Hotel & Travel",
      BEAUTY_WELLNESS: "Beauty & Wellness",
      BABY_KIDS: "Baby & Kids",
      FOOD_GROCERY: "Food & Grocery",
      EDUCATION: "Education",
    },
    orderOptions: {
      Featured: "Featured",
      "Highest cashback": "Highest cashback",
      "A-Z": "A-Z",
      "Z-A": "Z-A",
      "New offers": "New offers",
    },
    displayOptions: {
      Cards: "Cards",
      List: "List",
    },
  },
  successModal: {
    welcome: "Welcome to Coinmiles!",
    youGotBtc: "You just got 0.00005 BTC",
    youCanNowOfficially:
      "You can now officially say you got some Bitcoin for free. Amazing! Keep earning by using Coinmiles for your online purchases.",
    great: "Great!",
  },
  redirect: {
    expiredOffer: "Expired Offer",
    redirecting: "Redirecting you to...",
    activated: "Activated!",
    happyShopping: "Happy shopping",
    oops: "Oops! This offer is no longer valid.",
    pleaseTry: "Please try a different offer.",
    report: "Report",
    rewardsMessage: {
      one: "Your rewards will ",
      two: "automatically",
      three: " be added to your account.",
    },
  },
};
