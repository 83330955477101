import React, { useState, MouseEvent } from "react";

// Component imports
import { ExpandMoreIcon } from "@icons";
import { List, ListItem, ListItemText, Menu, MenuItem } from "@material";

// Misc Imports
import { useTranslation } from "@i18n";
import { withStyles } from "@styles";
import { FilterOption } from "@app-types";

const StyledListItem = withStyles((theme) => ({
  root: {
    display: "flex",
    padding: 8,
    justifyContent: "space-between",
    position: "relative",
    bottom: 0,
    border: `1px solid ${theme.palette.colors.border.light}`,
    borderRadius: 4,
    height: 44,
    width: 160,
    transitionProperty: "background-color",
    transitionDuration: "0.3s",
    marginRight: 12,
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      marginLeft: 4,
      marginRight: 0,
    },
    "&:focus": {
      backgroundColor: `${theme.palette.colors.background.lightGrey} !important`,
      transitionProperty: "background-color",
      transitionDuration: "0.3s",
    },
  },
}))(ListItem);

const StyledListItemText = withStyles((theme) => ({
  root: {
    paddingRight: 0,
    paddingLeft: 0,
  },
  primary: {
    fontSize: 15,
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
    },
    [theme.breakpoints.down(350)]: {
      fontSize: 11,
    },
  },
}))(ListItemText);

const StyledMenu = withStyles((theme) => ({
  paper: {
    marginTop: 8,
    border: `1px solid ${theme.palette.colors.border.light} !important`,
  },
}))(Menu);

const StyledMenuItem = withStyles((theme) => ({
  root: {
    transitionProperty: "background-color",
    transitionDuration: "0.3s",
    "&:hover": {
      backgroundColor: `${theme.palette.colors.background.lightGrey} !important`,
      transitionProperty: "background-color",
      transitionDuration: "0.3s",
    },
  },
  selected: {
    backgroundColor: `${theme.palette.colors.background.lightGrey} !important`,
  },
}))(MenuItem);

type Props = {
  order: string;
  setFilters: any;
};

export function OrderDropdown({ order, setFilters }: Props) {
  const { t } = useTranslation(["onlineScreen", "common"]);

  const [orderAnchorEl, setOrderAnchorEl] = useState<null | HTMLElement>(null);

  const orderOptions: FilterOption[] = [
    { id: "Featured", name: "Featured" },
    { id: "Highest cashback", name: "Highest cashback" },
    { id: "A-Z", name: "A-Z" },
    { id: "Z-A", name: "Z-A" },
    { id: "New offers", name: "New offers" },
  ];

  const orderOptionsTranslated: FilterOption[] = orderOptions.map(
    (option: FilterOption) => {
      return {
        id: option.id,
        name: t(`filtersHeader.orderOptions.${option.id}`),
      };
    },
  );

  const handleClickOrderMenu = (event: MouseEvent<HTMLElement>) => {
    setOrderAnchorEl(event.currentTarget);
  };

  const handleOrderMenuClose = () => {
    setOrderAnchorEl(null);
  };

  const handleOrderSelect = (
    event: MouseEvent<HTMLElement>,
    newOrder: string,
  ) => {
    newOrder !== null &&
      setFilters((prevState) => ({
        ...prevState,
        order: newOrder,
      }));
    setOrderAnchorEl(null);
  };

  return (
    <>
      <List aria-label="order-by" disablePadding={true}>
        <StyledListItem button onClick={handleClickOrderMenu}>
          <StyledListItemText
            primary={
              orderOptionsTranslated.find((trans) => trans.id === order).name
            }
          />
          <ExpandMoreIcon width={8} style={{ marginRight: 4 }} />
        </StyledListItem>
      </List>
      <StyledMenu
        id="order-by-menu"
        anchorEl={orderAnchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(orderAnchorEl)}
        onClose={handleOrderMenuClose}
        transitionDuration={600}
      >
        {orderOptionsTranslated.map(
          (orderOption: FilterOption, index: number) => (
            <StyledMenuItem
              key={index + 1}
              selected={orderOption.id === order}
              value={orderOption.name}
              onClick={(event) => handleOrderSelect(event, orderOption.id)}
            >
              {orderOption.name}
            </StyledMenuItem>
          ),
        )}
      </StyledMenu>
    </>
  );
}
