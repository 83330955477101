import React from "react";

// Misc Imports
import { AffiliateServiceArea, Language, Theme } from "@app-types";
import { makeStyles } from "@styles";
import { isServerSideRendering } from "@utils";

// Component imports
import { CanadaFlagIcon } from "@icons";
import { Typography } from "@material";

const useStyles = makeStyles((theme: Theme) => ({
  localeContainer: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    width: 60,
    marginRight: 10,
    [theme.breakpoints.down("xs")]: {
      marginRight: 2,
      width: 60,
    },
  },
  countryText: {
    color: theme.palette.black,
    paddingTop: 2,
    fontSize: 14,
    fontWeight: 600,
    marginLeft: 6,
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
}));

const languageOptions: Language[] = [
  { code: "en", fullName: "English", flag: CanadaFlagIcon },
  { code: "fr", fullName: "Français", flag: CanadaFlagIcon },
];

type Props = {
  currentCountry: AffiliateServiceArea;
  onClick: any;
};

export function NavbarLocaleSelectionButton({
  currentCountry,
  onClick,
}: Props) {
  if (isServerSideRendering()) {
    return <></>;
  }

  const classes: any = useStyles({});

  // Currently only language preference is available. Country can be added in by uncommenting related lines
  const localStorageLanguage: string = localStorage.getItem("i18nextLng");

  // Fetches full name/currency/flag/language from country/language code in redux state
  const currentLanguage: Language = languageOptions.find(
    (languageOption) => languageOption.code === localStorageLanguage,
  );

  if (currentCountry) {
    return (
      <div className={classes.localeContainer} onClick={onClick}>
        <img
          src={currentCountry.flag}
          width={24}
          alt={`${currentCountry.name}-flag`}
        />
        <Typography className={classes.countryText}>
          {currentLanguage.code.toUpperCase()}
        </Typography>
      </div>
    );
  } else {
    return null;
  }
}
