import React from "react";

// Component imports
import { DialogButton } from "@components";
import { Grid, Paper } from "@material";
import { CloseIcon } from "@icons";

// Misc Imports
import { Theme } from "@app-types";
import { useTranslation } from "@i18n";
import { makeStyles } from "@styles";
import { ModalHelper } from "@utils";

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  paper: {
    position: "relative",
    zIndex: 1302,
    borderRadius: 12,
    padding: 16,
    maxWidth: 800,
    width: 400,
    height: "auto",
    outline: "none !important",
    [theme.breakpoints.down("xs")]: {
      margin: "auto 16px auto 16px",
      height: "auto",
    },
  },
  confettiIconContainer: {
    marginTop: 16,
    height: 185,
  },
  cancelIconContainer: {
    position: "absolute",
    top: 12,
    right: 16,
    zIndex: 5,
  },
  cancelIcon: {
    cursor: "pointer",
  },
  textAndButtonsContainer: {
    padding: 32,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
    },
  },
  textContainer: { textAlign: "center" },
  headerText: {
    fontSize: 24,
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      fontSize: 21,
    },
  },
  subheaderText: {
    fontSize: 20,
    fontWeight: 700,
    marginBottom: 8,
    [theme.breakpoints.down("xs")]: {
      fontSize: 19,
    },
  },
  dialogText: {
    fontSize: 17,
    fontWeight: 400,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
    },
  },
  buttonContainer: { marginTop: 24 },
}));

// IN PROGRESS MODAL DEV NOTE -->
export function AdblockDetectedModal() {
  const classes: any = useStyles({});
  const { t } = useTranslation("successDialog");

  return (
    <>
      <Paper className={classes.paper}>
        <Grid
          item
          className={classes.cancelIconContainer}
          style={{ position: "absolute", alignItems: "center" }}
        >
          <CloseIcon
            className={classes.cancelIcon}
            width={13}
            fill={"black"}
            onClick={ModalHelper.close}
          />
        </Grid>
        <p>YOU HAVE AN AD BLOCKER ENABLED! SHAME!!</p>
        <Grid className={classes.buttonContainer}>
          <DialogButton
            colorVariant="pink"
            width="100%"
            height={60}
            onClick={ModalHelper.close}
          >
            {t("great")}
          </DialogButton>
        </Grid>
      </Paper>
    </>
  );
}
