import {
  currentUserVar,
  useMutation,
  useReactiveVar,
  UserPoolClient,
} from "@apollo";
import { MUTATION_UPDATE_USER } from "@queries";

import { CurrentUser } from "@app-types";
import { User } from "@models";
import { LocaleUtils } from "@utils";

type UpdateUserAttributes = {
  didLogin?: boolean;
  country?: string;
  language?: string;
  profilePhotoUrl?: string;
};

type Params = {
  onCompleted?: () => void;
  onError?: () => void;
};

type ReturnedFunctions = {
  updateUser: (params: UpdateUserAttributes) => void;
  updateUserLocaleIfDifferent: (user: User) => void;
  updateUserLocaleIfMissing: (user: User) => Promise<void>;
};

export const useUpdateUser = ({
  onCompleted,
  onError,
}: Params): ReturnedFunctions => {
  const user = useReactiveVar(currentUserVar);

  const [updateUserMutation] = useMutation<CurrentUser>(MUTATION_UPDATE_USER, {
    client: UserPoolClient,
    onCompleted: ({ user }) => {
      onCompleted && onCompleted();
      currentUserVar(user);
    },
    onError: (err) => {
      onError && onError();
      console.warn(`Error updating user: ${err}`);
    },
  });

  const updateUser = ({
    didLogin,
    country,
    language,
    profilePhotoUrl,
  }: UpdateUserAttributes) => {
    const updatedUser = {
      id: user.id,
      didLogin,
      country,
      language,
      profilePhotoUrl: profilePhotoUrl === null ? "" : profilePhotoUrl,
    };

    updateUserMutation({ variables: { ...updatedUser } });
  };

  const updateUserLocaleIfDifferent = (user: User): void => {
    const updatedUser: any = { id: user.id };

    const currentCountry = localStorage.getItem("userCountry");
    const currentLanguage = localStorage.getItem("i18nextLng");

    if (user.country !== currentCountry) updatedUser.country = currentCountry;
    if (user.language !== currentLanguage)
      updatedUser.language = currentLanguage;

    if (!!updatedUser.country || !!updatedUser.language) {
      updateUserMutation({ variables: { ...updatedUser } });
    }
  };

  const updateUserLocaleIfMissing = async (user: User): Promise<void> => {
    const updatedUser: any = { id: user.id };

    if (!!user.country && !!user.language) return;

    const {
      userCountry,
      userLanguageCode,
    } = await LocaleUtils.setSignInLocale();

    if (!user.country) updatedUser.language = userCountry;
    if (!user.language) updatedUser.language = userLanguageCode;

    if (!!updatedUser.country || !!updatedUser.language) {
      updateUserMutation({ variables: { ...updatedUser } });
    }
  };

  return { updateUser, updateUserLocaleIfDifferent, updateUserLocaleIfMissing };
};
