import {en} from './translations.en';
import {fr} from './translations.fr';

export const translations = {
  en: {
    common: en
  },
  fr: {
    common: fr
  }
};
