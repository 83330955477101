import React from "react";

// Component imports
import {Grid, Typography} from "@material";

// Misc Imports
import {Theme} from "@app-types";
import {useTranslation} from "@i18n";
import {makeStyles} from "@styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 4,
    marginTop: 21,
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },
  },
  headerText: {
    color: theme.palette.black,
    fontWeight: 800,
    fontSize: 42,
    [theme.breakpoints.down("xs")]: {
      fontSize: 32,
    },
    [theme.breakpoints.down(400)]: {
      fontSize: 28,
    },
    [theme.breakpoints.down(370)]: {
      fontSize: 24,
    },
  },
}));

export function OnlineHeader() {
  const classes: any = useStyles({});
  const {t} = useTranslation("common");

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Typography className={classes.headerText}>
          {t("shopOnline")}
        </Typography>
      </Grid>
    </div>
  );
}
