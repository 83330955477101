import { makeVar } from "@apollo/client";
import { AffiliateServiceArea } from "@app-types";
import { User } from "@models";

const currentCountryInitialValue: AffiliateServiceArea = null;

const currentUserInitialValue: User = null;

const modalStateInitialValue: ModalState = {
  modalType: null,
  modalProps: null,
  modalOptions: null,
};

export const currentCountryVar = makeVar(currentCountryInitialValue);

export const currentUserVar = makeVar(currentUserInitialValue);

export const modalStateVar = makeVar(modalStateInitialValue);
