export const fr = {
  shopOnline: "En ligne",
  localOffers: "Offres locales",
  myWallet: "Mon portefeuille",
  whatILike: "Ce que j’aime",
  login: "Se connecter",
  loginToEarn: "Se connecter",
  join: "Réclamer",
  adblockDetected: {
    bold: "Nous avons détecté un Adblock",
    message:
      "Veuillez désactiver votre Adblockers pour être récompensé avec Coinmiles.",
  },
};
