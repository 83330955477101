import React from "react";

// Component imports
import { DialogButton, Image, PayoutBanner } from "@components";
import { Grid, Paper, Typography } from "@material";

// Misc Imports
import { TermsData, Theme } from "@app-types";
import { useTranslation } from "@i18n";
import { makeStyles } from "@styles";
import { ModalHelper } from "@utils";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: 459,
    height: "auto",
    padding: 45,
    outline: "none !important",
    [theme.breakpoints.down("sm")]: {
      width: 400,
    },
    [theme.breakpoints.down("xs")]: {
      width: "95%",
      maxWidth: 320,
      padding: 24,
      margin: 0,
    },
  },
  dialogMobile: {
    height: 426,
    width: "90%",
    borderRadius: 8,
    marginLeft: "auto",
    marginRight: "auto",
  },
  brandLogoContainer: {
    height: 78,
    width: 78,
    maxWidth: 78,
    borderRadius: 8,
    border: "1px solid #DEDEDE",
    [theme.breakpoints.down("xs")]: {
      width: 78,
      border: "none",
    },
  },
  brandLogo: {
    margin: "auto",
    width: 60,
  },
  brandNameRow: {
    marginLeft: 16,
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      width: 320,
      justifyContent: "flex-start",
    },
  },
  advertiserName: {
    fontSize: 27,
    fontWeight: 500,
    color: "#000000",
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      marginBottom: 8,
    },
  },
  cashbackContainer: {},
  headerText: {
    marginTop: 24,
    fontSize: 24,
    fontWeight: 500,
    textAlign: "left",
  },
  termsText: {
    width: "100%",
    fontSize: 14,
    fontWeight: 400,
    textAlign: "left",
    marginTop: 12,
    marginBottom: 8,
  },
  earnButton: {
    width: 369,
    marginTop: 22,
  },
  closeButton: {
    width: 369,
    marginTop: 8,
  },
}));

export function TermsModal({
  name,
  terms,
  redirectData,
  openOfferRedirect,
}: TermsData) {
  const classes: any = useStyles({});
  const { t } = useTranslation("common");

  const handleRedirectFromDialog = () => {
    ModalHelper.close();
    openOfferRedirect(redirectData);
  };

  const createTermsTextWithNewLines = (termsText: string): JSX.Element[] =>
    termsText &&
    termsText.split("/n").map((item: string, key: number) => {
      return (
        <React.Fragment key={key}>
          {item}
          <br />
        </React.Fragment>
      );
    });

  return (
    <Paper className={classes.paper}>
      <Grid container direction="column">
        <Grid container direction="row" justify="flex-start">
          <Grid container item className={classes.brandLogoContainer}>
            <Image
              image={redirectData.advertiserLogoImage}
              className={classes.brandLogo}
              alt="offer-brand-logo"
            />
          </Grid>
          <Grid
            container
            item
            direction="column"
            alignItems="flex-start"
            xs
            className={classes.brandNameRow}
          >
            <Typography className={classes.advertiserName}>{name}</Typography>
            <PayoutBanner payoutText={redirectData.payoutText} />
          </Grid>
        </Grid>
        <Grid>
          <Typography className={classes.headerText}>
            {t("termsAndConditions")}
          </Typography>
        </Grid>
        <Grid container item>
          <Typography className={classes.termsText}>
            {createTermsTextWithNewLines(terms)}
          </Typography>
        </Grid>
        <Grid container item justify="flex-start">
          <Grid className={classes.earnButton}>
            <DialogButton
              colorVariant="black"
              width={"100%"}
              onClick={handleRedirectFromDialog}
            >
              {`${t("shopAt")} ${name}`}
            </DialogButton>
          </Grid>
          <Grid className={classes.closeButton}>
            <DialogButton
              colorVariant="white"
              width={"100%"}
              onClick={ModalHelper.close}
            >
              {t("keepExploring")}
            </DialogButton>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
