import React, { useState, MouseEvent } from "react";
import { motion } from "framer-motion";

// Component imports
import { FavouritedHeart, Image, PayoutBanner } from "@components";
import { RightArrowIcon } from "@icons";
import { Card, CardContent, Grid, Typography } from "@material";

// Misc Imports
import { OfferCardProps, Theme } from "@app-types";
import { useTranslation } from "@i18n";
import { makeStyles, cardVariants, arrowBounce } from "@styles";

const useStyles = (longName: boolean) =>
  makeStyles((theme: Theme) => ({
    root: {
      padding: 0,
      zIndex: 5000,
    },
    card: {
      position: "relative",
      width: "auto",
      borderRadius: 16,
      bottom: 0,
      height: 358,
      "&:hover": {
        cursor: "pointer",
      },
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
        marginRight: 0,
        height: 300,
      },
      [theme.breakpoints.down("md")]: { height: 300 },
      [theme.breakpoints.up("md")]: {
        transitionProperty: "transform, box-shadow, bottom",
        transitionDuration: "0.2s",
        transitionTimingFunction: "ease-out",
        "&:hover": {
          bottom: 4,
          boxShadow: "0 3px 6px 0 rgba(209,209,209,0.5)",
          transitionProperty: "transform, box-shadow, bottom",
          transitionDuration: "0.2s",
        },
      },
    },
    cardPressed: {
      transform: "scale(0.97) !important",
      bottom: 0,
      transitionProperty: "transform, box-shadow, bottom",
      transitionDuration: "0.3s",
    },
    cardContent: { padding: 0 },
    bottomContainer: {
      position: "relative",
      bottom: 32,
      width: "100%",
      border: `1px solid #efefef`,
      borderTop: 0,
      borderBottomLeftRadius: 16,
      borderBottomRightRadius: 16,
    },
    brandInfoContainer: {
      width: "100%",
      height: 303,
      marginBottom: 8,
      marginLeft: "auto",
      marginRight: "auto",
    },
    brandImageContainer: {
      width: "100%",
      height: 258,
      borderRadius: "0px",
      zIndex: 3,
      [theme.breakpoints.down("md")]: { height: 200 },
    },
    brandImage: {
      height: "100%",
      width: "100%",
      objectFit: "cover",
    },
    brandLogoRow: {
      marginBottom: 12,
      paddingLeft: 14,
    },
    brandLogoContainer: {
      height: 62,
      width: 62,
      borderRadius: 8,
      marginTop: 8,
      backgroundColor: "white",
      border: "1px solid #E6E6E6",
      zIndex: 4,
    },
    brandLogo: {
      margin: "auto",
      width: 50,
      height: 50,
    },
    brandNameContainer: { width: "auto" },
    advertiserName: {
      fontSize: longName ? 15 : 18,
      marginLeft: longName ? 6 : 8,
      marginRight: "auto",
      fontWeight: 500,
      color: "black",
    },
    cashbackRowContainer: {
      marginTop: 3,
      marginBottom: 15,
      paddingLeft: 14,
      paddingRight: 10,
    },
    termsButton: {
      display: "flex",
      alignItems: "center",
      width: 72,
      height: 31,
      fontSize: 14,
      fontWeight: 500,
    },
    heartIconContainer: {
      position: "relative",
      left: "-14px",
      bottom: 244,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 32,
      height: 32,
    },
  }));

export function GridCardView({
  offer,
  userId,
  favourited,
  onRedirectClicked,
  onTermsModalClicked,
  onSignUpModalClicked,
}: OfferCardProps) {
  const longName: boolean = offer.advertiserName.length >= 18;
  const classes: any = useStyles(longName)({});
  const { t } = useTranslation("common");
  const localStorageLanguage: string = localStorage.getItem("i18nextLng");

  const [animation, setAnimation] = useState<boolean>(false);
  const [termsVisible, setTermsVisible] = useState<boolean>(false);

  const handleMouseHover = (): void => {
    setTermsVisible(!termsVisible);
  };

  const handleTermsButtonClick = (
    event: MouseEvent<HTMLInputElement>,
  ): void => {
    if (event.button === 0) {
      event.stopPropagation();
      onTermsModalClicked(offer);
    }
  };

  const handleCardClick = (event: MouseEvent<HTMLInputElement>): void => {
    if (event.button === 0) {
      userId ? handleCardPressed() : onSignUpModalClicked(offer);
    }
  };

  const handleCardPressed = (): void => {
    setAnimation(true);
    setTimeout(() => {
      setAnimation(false);
      onRedirectClicked(offer);
    }, 400);
  };

  const payoutTextLocalized: string =
    localStorageLanguage === "fr" && offer.payoutText.includes("$")
      ? `${offer.payoutText.substring(1)}$`
      : offer.payoutText;

  return (
    <Card className={`${classes.card} ${animation && classes.cardPressed}`}>
      <CardContent
        onMouseEnter={!termsVisible ? handleMouseHover : undefined}
        onMouseLeave={termsVisible ? handleMouseHover : undefined}
        onMouseDown={handleCardClick}
        className={classes.cardContent}
      >
        <Grid
          container
          item
          justify="center"
          alignItems="center"
          className={classes.brandInfoContainer}
        >
          <Grid
            container
            item
            direction="row-reverse"
            justify="flex-start"
            alignItems="flex-start"
            className={classes.brandImageContainer}
          >
            <Image
              image={offer.backgroundImage}
              className={classes.brandImage}
              alt={`${offer.id}-brand-background`}
            />
            <div className={classes.heartIconContainer}>
              <FavouritedHeart
                userId={userId}
                offerId={offer.id}
                favourited={favourited}
                fill="white"
                size={27}
              />
            </div>
          </Grid>
          <Grid
            container
            item
            direction="column"
            justify="flex-start"
            className={classes.bottomContainer}
          >
            <Grid
              container
              item
              direction="row"
              justify="flex-start"
              alignItems="flex-end"
              className={classes.brandLogoRow}
            >
              <Grid
                container
                item
                justify="center"
                alignItems="center"
                className={classes.brandLogoContainer}
              >
                <Image
                  image={offer.advertiserLogoImage}
                  className={classes.brandLogo}
                  alt={`${offer.id}-brand-logo`}
                />
              </Grid>
              <Grid item className={classes.brandNameContainer}>
                <div>
                  <Typography className={classes.advertiserName}>
                    {offer.advertiserName}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              item
              direction="row"
              justify="space-between"
              alignItems="center"
              className={classes.cashbackRowContainer}
            >
              <PayoutBanner payoutText={payoutTextLocalized} />
              <Grid>
                {userId && termsVisible && (
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={cardVariants}
                    whileHover="hover"
                  >
                    <div
                      onMouseDown={handleTermsButtonClick}
                      className={classes.termsButton}
                    >
                      {t("details")}
                      <motion.div variants={arrowBounce}>
                        <RightArrowIcon
                          width={12}
                          fill={"black"}
                          style={{
                            marginLeft: 4,
                            marginTop: 7,
                          }}
                        />
                      </motion.div>
                    </div>
                  </motion.div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
