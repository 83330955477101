import { useEffect } from "react";

import { Auth } from "@aws-amplify/auth";
import { Hub, HubCapsule } from "@aws-amplify/core";

import { currentCountryVar, currentUserVar, UserPoolClient } from "@apollo";
import { USER_POOL_GET_FINANCE_DATA } from "@queries";

// Misc imports
import { useUpdateUser } from "@hooks";
import {
  AuthUtils,
  fetchSignInData,
  getAffiliateServiceArea,
  isServerSideRendering,
  LocaleUtils,
  ModalHelper,
} from "@utils";
import { User } from "@models";

// Misc imports
import { FinanceData } from "@app-types";

const isValidSignIn = (authenticationFlowType: string): boolean =>
  authenticationFlowType === "USER_PASSWORD_AUTH";

type HubCallbackAsync = (capsule: HubCapsule) => Promise<void>;

export function useSocialAuthListener(): void {
  if (isServerSideRendering()) {
    return;
  }

  const { updateUserLocaleIfDifferent } = useUpdateUser({});

  const socialSignIn = async (): Promise<void> => {
    const cognitoUser = await Auth.currentAuthenticatedUser();

    const currencyCode = getAffiliateServiceArea(
      localStorage.getItem("userCountry"),
    ).currencyCode;

    try {
      const user = await fetchSignInData(cognitoUser, currencyCode);

      if (user) {
        const { userCountry } = await LocaleUtils.setSignInLocale();

        updateUserLocaleIfDifferent(user);

        UserPoolClient.query<FinanceData>({
          query: USER_POOL_GET_FINANCE_DATA,
          variables: { convertedCurrency: userCountry.currencyCode },
        });

        currentCountryVar(userCountry);
        currentUserVar(user);
        ModalHelper.close();
      } else {
        await Auth.signOut();
        currentUserVar(null);
        ModalHelper.close();
        throw new Error("No user ID found.");
      }
    } catch (err) {
      throw new Error(err);
    }
  };

  // Listener for Auth events for OAuth social login
  useEffect(() => {
    const authEventHandler = async ({
      payload: { data, event },
    }: HubCapsule) => {
      if (!data?.authenticationFlowType) return;

      switch (event) {
        case "signIn":
          if (isValidSignIn(data?.authenticationFlowType)) {
            try {
              ModalHelper.open({
                modalType: "socialAuthModal",
                modalOptions: { disableBackdropClick: true },
              });

              await socialSignIn();
            } catch (err) {
              console.error(`Social Sign-in error: ${err}`);
              ModalHelper.close();
            }
          }
          break;
        case "signIn_failure":
          await Auth.signOut();
          currentUserVar(null);
          console.error("Cognito sign in failed due to signIn_failure.");
          break;
      }
    };

    Hub.listen("auth", authEventHandler as HubCallbackAsync);

    return () => Hub.remove("auth", authEventHandler as HubCallbackAsync);
  }, []);
}
